(function () {
    'use strict';

    const adherenceRow = {
        bindings: {
            score: '=',
            date: '='
        },
        controller: AdherenceRowController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/adherence-report/adherence-row.html'
    };

    angular
        .module('continuumplatformApp')
        .component("adherenceRow", adherenceRow);

    AdherenceRowController.$inject = [];

    function AdherenceRowController() {
        const vm = this;
    }
})();
