(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('patient-therapy', {
                parent: 'patients',
                url: '/patient-therapy?page&sort',
                data: {
                    authorities: ['ROLE_PRACTITIONER'],
                    pageTitle: 'global.menu.patient-therapy-list'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/patient-therapy/patient-therapy-list.html',
                        controller: 'PatientTherapyListController',
                        controllerAs: 'vm'
                    }
                },
                dynamic: true,
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    filters: {
                        value: {
                            patientStatus: null,
                            programId: null,
                            therapyId: null,
                            healthFacilityId: null,
                            prescriberId: null,
                            status: ["S11_VALIDATED", "S20_ACTIVE", "S30_PAUSED"],
                            patientUserStatus: null
                        }
                    }
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('coordination');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('patientStatus');
                        $translatePartialLoader.addPart('patientTherapy');
                        $translatePartialLoader.addPart('patientTherapyStatus');
                        $translatePartialLoader.addPart('patientTreatment');
                        $translatePartialLoader.addPart('patientTreatmentStatus');
                        $translatePartialLoader.addPart('patientTreatmentPhase');
                        $translatePartialLoader.addPart('medicationInfoType');
                        $translatePartialLoader.addPart('medicationVariant');
                        $translatePartialLoader.addPart('medication');
                        $translatePartialLoader.addPart('therapy');
                        $translatePartialLoader.addPart('gender');
                        $translatePartialLoader.addPart('prescription');
                        $translatePartialLoader.addPart('global');
                        $translatePartialLoader.addPart('practitioner');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('visitType');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        $translatePartialLoader.addPart('healthFacilityType');
                        $translatePartialLoader.addPart('pharmacyOrder');
                        $translatePartialLoader.addPart('pharmacyOrderStatus');
                        return $translate.refresh();
                    }]
                }
            })
            .state('patient-therapy.request', {
                url: '/request',
                data: {
                    authorities: ['PATIENT_THERAPY_REQUEST']
                },
                redirectTo: (trans) => {
                    let Principal = trans.injector().get('Principal');
                    return Principal.identity().then(account =>
                        account.practitioner.patientSearchConnector === 'LIFEN' ? 'patient-search' : 'patient-draft');
                }
            })
            .state('patient-therapy-detail', {
                parent: 'patient-therapy',
                url: '/{id}',
                data: {
                    authorities: ['ROLE_PRACTITIONER'],
                    pageTitle: 'custom'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/patient-therapy/patient-therapy-detail.html',
                        controller: 'PatientTherapyDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('patientStatus');
                        $translatePartialLoader.addPart('patientTherapy');
                        $translatePartialLoader.addPart('patientTherapyStatus');
                        $translatePartialLoader.addPart('visit');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('visitType');
                        $translatePartialLoader.addPart('sideEffectReport');
                        $translatePartialLoader.addPart('adherenceReport');
                        $translatePartialLoader.addPart('measure');
                        $translatePartialLoader.addPart('media');
                        $translatePartialLoader.addPart('message');
                        $translatePartialLoader.addPart('prescription');
                        $translatePartialLoader.addPart('prescriptionItem');
                        $translatePartialLoader.addPart('patientTreatment');
                        $translatePartialLoader.addPart('patientTreatmentStatus');
                        $translatePartialLoader.addPart('patientTreatmentPhase');
                        $translatePartialLoader.addPart('medicationInfoType');
                        $translatePartialLoader.addPart('medicationVariant');
                        $translatePartialLoader.addPart('medication');
                        $translatePartialLoader.addPart('biologyResult');
                        $translatePartialLoader.addPart('practitionerSpeciality');
                        $translatePartialLoader.addPart('healthFacility');
                        $translatePartialLoader.addPart('healthFacilityType');
                        $translatePartialLoader.addPart('practitioner');
                        $translatePartialLoader.addPart('pharmacyOrder');
                        $translatePartialLoader.addPart('pharmacyOrderStatus');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'PatientTherapy', function ($stateParams, PatientTherapy) {
                        return PatientTherapy.get({id: $stateParams.id}).$promise;
                    }]
                }
            })
            .state('patient-therapy-detail.add', {
                url: '/add?type&pricing',
                data: {
                    authorities: ['PATIENT_THERAPY_MANAGE']
                },
                params: {
                    type: {
                        value: null
                    },
                    pricing: {
                        value: null
                    }
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/visit/visit-edition.html',
                        controller: 'VisitEditionController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$transition$', 'Visit', ($transition$, Visit) => {
                        return Visit.preCreate({
                            patientTherapyId: parseInt($transition$.params().id),
                            type: $transition$.params().type,
                            pricing: $transition$.params().pricing,
                        }).$promise;
                    }],
                    previousState: ['$transition$', function ($transition$) {
                        return {
                            name: $transition$.from().name || 'visit',
                            params: $transition$.params('from')
                        };
                    }]
                }
            });
    }

})();
