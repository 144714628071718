(function() {
    'use strict';

    var visitLastBio = {
        bindings: {
            visit: '='
        },
        controller: VisitLastBioController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-last-bio.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitLastBio', visitLastBio);

    VisitLastBioController.$inject = ['$uibModal', '$scope', '$state', '$sce', 'Lightbox', 'Visit', 'BiologyResult'];

    function VisitLastBioController($uibModal, $scope, $state, $sce, Lightbox, Visit, BiologyResult) {

        const vm = this;

        const now = new Date();

        vm.$onInit = () => {

            if(!vm.visit.plannedDate) {
                return;
            }

            if(moment(vm.visit.plannedDate).subtract(7, 'days').isAfter(now)) {
                return;
            }

            vm.isDisplayed = true;
            vm.isLoading = true;

            load();

            $scope.$watch('vm.visit', (newVal, oldVal) => {
                if (newVal && newVal !== oldVal) {
                    load();
                }
            });
        };

        vm.openImage = media => {
            Lightbox.openModal([{
                caption: media.text,
                url: media.trustedInlineUrl
            }], 0);
        };

        vm.openBiologyImport = () => {
            $uibModal.open({
                templateUrl: 'app/activity/biology-result/biology-result-import.html',
                controller: 'BiologyResultImportController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    patientId: () => vm.visit.patientId
                }
            }).result.then(() => {
                load();
            }, angular.noop);
        };

        function load() {
            loadBiology().then(() => {
                vm.canAddBio = vm.hasBio === false
                    && moment(vm.visit.plannedDate).isAfter(now);
            }).finally(() => {
                vm.isLoading = false;
            });
        }

        function loadBiology() {
            vm.hasBio = null;
            vm.biologyResults = null;
            return BiologyResult.query({
                view: 'media',
                'patientId.equals': vm.visit.patientId,
                'date.greaterThan': moment(vm.visit.plannedDate).subtract(7, 'day').toDate(),
                'date.lessThan': vm.visit.plannedDate,
                sort: 'date,desc',
                size: 3
            }).$promise.then((results) => {
                vm.hasBio = results.length > 0;
                vm.biologyResults = results.map(br => {
                    br.media = br.media.map(m => {
                        m.trustedInlineUrl = $sce.trustAsResourceUrl('/api/media/' + m.id + '/download?disposition=inline');
                        return m;
                    });
                    return br;
                });
            });
        }

    }

})();
