(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PractitionerController', PractitionerController);

    PractitionerController.$inject = ['$state', '$stateParams', 'Practitioner', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', 'HealthFacility'];

    function PractitionerController($state, $stateParams, Practitioner, ParseLinks, AlertService, paginationConstants, pagingParams, HealthFacility) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.ascending = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.toggleAdvancedSearch = toggleAdvancedSearch;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.currentSearch = $stateParams.search;

        vm.advancedSearch = $stateParams.advancedSearch;

        vm.practitioner = {
            job: $stateParams.job,
            speciality: $stateParams.speciality,
            email: $stateParams.email,
            nationalIdentifier: $stateParams.nationalIdentifier,
            firstName: $stateParams.firstName,
            postalCode: $stateParams.postalCode,
            city: $stateParams.city,
            lastName: $stateParams.lastName,
            healthFacilityName: $stateParams.healthFacilityName,
            validated: $stateParams.validated,
            legalStatus: $stateParams.legalStatus,
            userStatus: $stateParams.userStatus,
            badges: $stateParams.badges
        };

        vm.jobs = Practitioner.getJobs();

        Practitioner.getSpecialities()
            .then((response) => vm.specialities = response)
            .catch(() => vm.specialities = []);

        loadAll();

        function loadAll() {
            Practitioner.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                "query": vm.currentSearch,
                "job.equals": vm.practitioner.job,
                "speciality.equals": vm.practitioner.speciality,
                "nationalIdentifier.contains": vm.practitioner.nationalIdentifier,
                "email.contains": vm.practitioner.email,
                "firstName.startsWithNormalized": vm.practitioner.firstName,
                "lastName.startsWithNormalized": vm.practitioner.lastName,
                "postalCodeOrFacilityPostalCode.startsWith": vm.practitioner.postalCode,
                "cityOrFacilityCity.containsNormalized": vm.practitioner.city,
                "userStatus.equals": vm.practitioner.userStatus && vm.practitioner.userStatus !== 'NO_USER' ? vm.practitioner.userStatus : undefined,
                "user.specified": vm.practitioner.userStatus === 'NO_USER' ? false : undefined,
                "validated.equals": vm.practitioner.validated,
                "legalStatus.equals": vm.practitioner.legalStatus,
                "healthFacilityName.containsNormalized": vm.practitioner.healthFacilityName,
                "badges.contains": vm.practitioner.badges
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.ascending ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.practitioners = data;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.ascending ? 'asc' : 'desc'),
                search: vm.currentSearch,
                advancedSearch: vm.advancedSearch,
                job: vm.practitioner.job,
                speciality: vm.practitioner.speciality,
                email: vm.practitioner.email,
                nationalIdentifier: vm.practitioner.nationalIdentifier,
                firstName: vm.practitioner.firstName,
                postalCode: vm.practitioner.postalCode,
                city: vm.practitioner.city,
                lastName: vm.practitioner.lastName,
                healthFacilityName: vm.practitioner.healthFacilityName,
                validated: vm.practitioner.validated,
                userStatus: vm.practitioner.userStatus,
                legalStatus: vm.practitioner.legalStatus,
                badges: vm.practitioner.badges
            });
        }

        function search(searchQuery) {
            if(!vm.advancedSearch) {
                if (!searchQuery) {
                    return vm.clear();
                }
                vm.currentSearch = searchQuery;
            }
            vm.links = null;
            vm.page = 1;
            vm.ascending = false;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.ascending = false;
            vm.currentSearch = null;
            vm.practitioner = {};
            vm.transition();
        }

        function toggleAdvancedSearch() {
            vm.advancedSearch = !vm.advancedSearch;
            if (vm.currentSearch !== null || !vm.advancedSearch) {
                clear();
            }
        }
    }
})();
