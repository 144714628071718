(() => {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('pecam-operator-bill', {
                parent: 'remuneration',
                url: '/pecam-operator-bill',
                data: {
                    authorities: ['PECAM_OPERATOR_BILL'],
                    pageTitle: 'global.menu.remuneration.pecam-operator-bill'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/pecam/operator-bill/pecam-operator-bill.html',
                        controller: 'PecamOperatorBillController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        return $translate.refresh();
                    }],
                }
            });
    }

})();
