(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('done-visits', {
                parent: 'patients',
                url: '/done-visits?page&sort',
                data: {
                    authorities: ['ROLE_PRACTITIONER'],
                    pageTitle: 'global.menu.done-visits',
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/visit/done/done-visits.html',
                        controller: 'DoneVisitsController',
                        controllerAs: 'vm',
                    },
                },
                params: {
                    page: {
                        value: '1',
                        squash: true,
                        dynamic: true,
                    },
                    sort: {
                        value: 'date,desc',
                        squash: true,
                        dynamic: true,
                    },
                    filters: {
                        value: {
                            facilityDoctorId: undefined,
                            scoreEqualTo: undefined,
                            showHistory: false,
                            pre: null,
                            biology: undefined,
                            other: undefined
                        },
                        dynamic: true,
                    },
                },
                resolve: {
                    filters: ['$transition$', $transition$ => $transition$.params().filters],
                    pagingParams: ['$transition$', 'PaginationUtil', ($transition$, PaginationUtil) => ({
                        page: PaginationUtil.parsePage($transition$.params().page),
                        sort: $transition$.params().sort,
                        predicate: PaginationUtil.parsePredicate($transition$.params().sort),
                        ascending: PaginationUtil.parseAscending($transition$.params().sort),
                    })],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('adherenceReport');
                        $translatePartialLoader.addPart('medication');
                        $translatePartialLoader.addPart('visit');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('patientTherapy');
                        $translatePartialLoader.addPart('visitType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                },
            });
    }

})();
