(function() {
    'use strict';

    var visitPrerequisites = {
        bindings: {
            visit: '='
        },
        controller: VisitPrerequisitesController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-prerequisites.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitPrerequisites', visitPrerequisites);

    VisitPrerequisitesController.$inject = ['$uibModal', '$scope', '$state', 'Visit', 'SideEffectReport', 'PatientTherapy', 'HDJ'];

    function VisitPrerequisitesController($uibModal, $scope, $state, Visit, SideEffectReport, PatientTherapy, HDJ) {

        const vm = this;

        vm.$onInit = () => {

            if(!vm.visit.plannedDate) {
                return;
            }

            vm.isLoading = true;

            load();

            $scope.$watch('vm.visit', (newVal, oldVal) => {
                if (newVal && newVal !== oldVal) {
                    load();
                }
            });
        };

        vm.previewSideEffectReport = id => {
            $uibModal.open({
                templateUrl: 'app/activity/side-effect-report/side-effect-report-preview.html',
                controller: 'SideEffectReportPreviewController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function() {
                        return SideEffectReport.get({ id: id }).$promise;
                    },
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('sideEffectReport');
                        return $translate.refresh();
                    }]
                }
            }).result.then(angular.noop, angular.noop);
        };

        vm.openMeasureChart = type => {
            $uibModal.open({
                templateUrl: 'app/activity/measure/measure-chart.html',
                controller: 'MeasureChartController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    type: function() {
                        return type;
                    },
                    patientId: function() {
                        return vm.visit.patientId;
                    },
                    translatePartialLoader: ['$translate', function($translate) {
                        return $translate.refresh();
                    }]
                }
            }).result.then(angular.noop, angular.noop);
        };

        vm.startAdhocVisit = () => {
            const adhocVisit = {
                name: 'Questionnaire pré-cure',
                patientTherapyId: vm.visit.patientTherapyId,
                steps: ['measures', 'pain', 'symptoms', 'end'],
                type: 'QUESTIONNAIRE',
                adhoc: true,
                pre: true,
            };

            Visit.createOwn(adhocVisit).$promise.then(result => {
                $state.go('visit-wizard', { id: result.id });
            });
        };

        vm.addVisit = (type, pricing) => {
            vm.isAddingVisit = true;
            HDJ.createPreHospitalVisit({
                hospitalVisitId: vm.visit.id,
                type,
                pricing,
            })
                .then(response =>
                    Visit.get({ id: response.data }).$promise)
                .then(newPreHospitalVisit => {
                    vm.previousTodoVisits.push(newPreHospitalVisit);
                    if (['STD_HOME', 'PHARMA_ITW'].includes(newPreHospitalVisit.type)) {
                        $state.go('visit-detail.edit', { id: newPreHospitalVisit.id });
                    }
                })
                .finally(() => {
                    vm.isAddingVisit = false;
                });
        };

        vm.openPractitioner = practitioner => {
            $uibModal.open({
                templateUrl: 'app/entities/practitioner/practitioner-modal.html',
                controller: 'PractitionerModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    practitioner: () => practitioner
                }
            });
        };

        function load() {
            loadPatientTherapy()
                .then(loadPreviousVisit)
                .then(() => {
                    if (vm.visit.plannedDate) {
                        const now = new Date();
                        const futureAppointment = moment(vm.visit.plannedDate).isAfter(now);
                        const futureAppointmentWithNoAnswer = vm.hasDone === false && futureAppointment;
                        vm.canStartVisit = futureAppointmentWithNoAnswer && moment(vm.visit.plannedDate).subtract(7, 'days').isBefore(now);
                        vm.canAddVisit = futureAppointmentWithNoAnswer && vm.previousTodoVisits.length === 0 && vm.patientTherapy.actions.includes('ADD_VISIT');
                        vm.canAskToAddVisit = futureAppointmentWithNoAnswer && vm.previousTodoVisits.length === 0 && !vm.patientTherapy.actions.includes('ADD_VISIT');
                        console.log();
                    }
                })
                .finally(() => {
                    vm.isLoading = false;
                });
        }

        function loadPatientTherapy() {
            return PatientTherapy.get({ id: vm.visit.patientTherapyId }).$promise.then(patientTherapy => {
                vm.patientTherapy = patientTherapy;
            });
        }

        function loadPreviousVisit() {
            vm.hasDone = null;
            vm.lastPreviousDoneVisit = null;
            vm.previousTodoVisits = null;
            return Visit.query({
                'patientId.equals': vm.visit.patientId,
                'steps.contains': 'symptoms',
                'date.greaterThan': moment(vm.visit.plannedDate).subtract(7, 'day').toDate(),
                'date.lessThan': vm.visit.plannedDate,
                sort: 'date,desc',
                size: 1
            }).$promise.then(done => {
                vm.hasDone = done.length === 1;
                if (vm.hasDone) {
                    vm.lastPreviousDoneVisit = done[0];
                    // récupération d'un éventuel questionnaire à renseigner après le dernier renseigné
                    return Visit.query({
                        'patientId.equals': vm.visit.patientId,
                        'steps.contains': 'symptoms',
                        'plannedOrLimitOrDueDate.greaterThan': vm.lastPreviousDoneVisit.date,
                        'plannedOrLimitOrDueDate.lessThan': vm.visit.limitDate || vm.visit.plannedDate,
                        'status.notEquals': 'DONE',
                        sort: 'plannedOrLimitOrDueDate,desc',
                        size: 3
                    }).$promise.then(mapPreviousTodoVisits);
                } else {
                    return Visit.query({
                        'patientId.equals': vm.visit.patientId,
                        'steps.contains': 'symptoms',
                        'plannedOrLimitOrDueDate.greaterThan': moment(vm.visit.plannedDate).subtract(7, 'day').toDate(),
                        'plannedOrLimitOrDueDate.lessThan': vm.visit.limitDate || vm.visit.plannedDate,
                        'status.notEquals': 'DONE',
                        'patientTherapyStatus.in': ['S20_ACTIVE', 'S30_PAUSED'],
                        sort: 'plannedOrLimitOrDueDate,desc',
                        size: 3
                    }).$promise.then(mapPreviousTodoVisits);
                }
            });
        }

        function mapPreviousTodoVisits(previousVisits) {
            vm.previousTodoVisits = previousVisits.map(previous => {
                previous.isReminderInFuture = previous.nextReminderDate && moment(previous.nextReminderDate).isAfter();
                previous.warn = new Date(previous.plannedDate || previous.limitDate || previous.dueDate) > vm.visit.plannedDate;
                return previous;
            });
        }

    }

})();
