(function() {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('CloseDays', CloseDays);

    CloseDays.$inject = ['$http'];

    function CloseDays($http) {

        return { get };

        function get(organizationId, date) {
            return $http.get('api/close-days/' + organizationId + '/' + date);
        }

    }

})();
