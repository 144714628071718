(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('HealthFacilityDialogController', HealthFacilityDialogController);

    HealthFacilityDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'HealthFacility', 'Address', 'FeatureFlags', 'Timezone'];

    function HealthFacilityDialogController($timeout, $scope, $stateParams, $uibModalInstance, entity, HealthFacility, Address, FeatureFlags, Timezone) {
        const vm = this;

        vm.healthFacility = entity;
        vm.clear = clear;
        vm.save = save;
        vm.searchAddress = searchAddress;
        vm.onSelectAddress = onSelectAddress;

        vm.types = HealthFacility.getTypes();
        vm.timezones = Timezone.getTimezones();

        FeatureFlags.getSelfService().then(features => vm.features = features);

        vm.$onInit = function () {
            if (vm.healthFacility) {
                vm.address = {properties: {
                    label: `${vm.healthFacility.address || ''} ${vm.healthFacility.postalCode || ''} ${vm.healthFacility.city || ''}`
                }};
            }
        };

        $timeout(function () {
            angular.element('.form-group:eq(0)>select').focus();
        });

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            vm.isSaving = true;
            if (vm.healthFacility.id !== null) {
                HealthFacility.update(vm.healthFacility, onSaveSuccess, onSaveError);
            } else {
                HealthFacility.save(vm.healthFacility, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess(result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }

        function searchAddress(search) {
            vm.addressInvalid = !!search;

            if (!search) {
                vm.healthFacility.address = null;
                vm.healthFacility.postalCode = null;
                vm.healthFacility.city = null;

                vm.healthFacility.latitude = null;
                vm.healthFacility.longitude = null;
                vm.healthFacility.cityCode = null;
            }

            if (!search || search.length < 3) {
                return;
            }

            return Address.search(search);
        }

        function onSelectAddress($item, $model, $label, $event)  {
            vm.addressInvalid = false;
            if (vm.healthFacility) {
                vm.healthFacility.address = ['housenumber', 'street', 'locality'].includes($item.properties.type) ? $item.properties.name : null;
                vm.healthFacility.postalCode = $item.properties.postcode;
                vm.healthFacility.city = $item.properties.city;

                vm.healthFacility.latitude = $item.geometry && $item.geometry.coordinates && $item.geometry.coordinates[1] ? $item.geometry.coordinates[1] : vm.healthFacility.latitude;
                vm.healthFacility.longitude = $item.geometry && $item.geometry.coordinates && $item.geometry.coordinates[0] ? $item.geometry.coordinates[0] : vm.healthFacility.longitude;
                vm.healthFacility.cityCode = $item.properties.citycode;
            }
        }

    }
})();
