(() => {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PecamOperatorBillController', PecamOperatorBillController);

    PecamOperatorBillController.$inject = ['$log', '$scope', '$state', '$uibModal', '$window', 'moment', 'paginationConstants', 'PecamOperatorBill', 'DownloadFile', 'Upload', 'Principal', 'HealthFacility'];

    function PecamOperatorBillController($log, $scope, $state, $uibModal, $window, moment, paginationConstants, PecamOperatorBill, DownloadFile, Upload, Principal, HealthFacility) {

        const vm = this;

        vm.prescriptionDownloads = {};

        vm.view = 'MONTHLY';

        vm.account = null;
        vm.pecamFeatureMissing = null;
        vm.history = false;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.page = 1;
        vm.countPending = null;
        vm.countHistory = null;
        vm.countPagination = null;
        vm.rows = null;

        vm.selecting = false;
        vm.selectError = null;

        vm.exporting = false;
        vm.exportError = null;

        vm.yesterday = moment().subtract(1, 'days').toDate();

        vm.initialized = false;

        vm.$onInit = () => {
            load();

            $scope.$watch('vm.view', (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    vm.page = 1;
                    select();
                }
            })
        };

        vm.$onDestroy = () => {

        };

        function load() {
            Principal.identity().then(account => {
                if (!account.practitioner || !account.practitioner.healthFacilityId) {
                    $log.warn("Non PS, ou non affecté à un établissement");
                    $state.go('accessdenied');
                    return;
                }
                return vm.account = account;
            })
                .then(loadFacilitySummary)
                .then(checkFeature)
                .then(select)
                .then(() => {
                    if(vm.countPending === 0) {
                        vm.toggleHistory().then(() => {
                            vm.initialized = true;
                        });
                    } else {
                        vm.initialized = true;
                    }
                })
                .catch($log.error);
        }

        function loadFacilitySummary(account) {
            return HealthFacility.get({
                id: account.practitioner.healthFacilityId,
                view: 'summary'
            }).$promise.then(facility => vm.facility = facility);
        }

        function checkFeature(facility) {
            vm.pecamFeatureMissing = !facility.features.includes('PECAM');
            if (vm.pecamFeatureMissing) {
                return Promise.reject("La prise en charge de la télésurveillance médicale par l'A.M. n'est pas activée dans cet établissement");
            }
            return Promise.resolve();
        }

        function select() {
            $log.debug("selecting");
            vm.selecting = true;
            vm.selectError = null;
            let promise;
            if (vm.view === 'MONTHLY') {
                promise = selectMonthly();
            } else if(vm.view === 'TOTAL') {
                promise = selectTotal();
                vm.history = false;
            } else {
                promise = Promise.reject("Unsupported view");
            }
            return promise.catch(response => {
                if(response.data && response.data.detail) {
                    vm.selectError = response.data.detail;
                } else {
                    vm.selectError = response;
                }
            }).finally(() =>
                vm.selecting = false
            );
        }

        function selectMonthly() {
            return PecamOperatorBill.selectMonthly(vm.history, vm.page - 1, vm.itemsPerPage).then(response => {
                vm.countPagination = parseInt(response.headers('X-Total-Count'));
                if (vm.history) {
                    vm.countHistory = vm.countPagination;
                    PecamOperatorBill.count(false).then(response => {
                        vm.countPending = response.data;
                    });
                } else {
                    vm.countPending = vm.countPagination;
                    PecamOperatorBill.count(true).then(response => {
                        vm.countHistory = response.data;
                    });
                }
                vm.rows = response.data.map(mapRow);
            });
        }

        function selectTotal() {
            return PecamOperatorBill.selectTotal().then(response => {
                vm.countPending = parseInt(response.headers('X-Total-Count'));
                PecamOperatorBill.count(true).then(response => {
                    vm.countHistory = response.data;
                });
                vm.rows = response.data.map(mapRow);
            });
        }

        function mapRow(row) {
            row.missing = (!row.ssn || !row.birthDate);
            return row;
        }

        vm.exportCsv = () => {
            vm.exporting = true;
            vm.exportError = null;
            PecamOperatorBill.exportCsv()
                .then(response => DownloadFile.downloadFile(transformExport(response)))
                .catch(response => {
                    vm.exportError = response.data.detail;
                })
                .finally(() => {
                    vm.exporting = false;
                });
        };

        function transformExport(response) {
            return {
                data: new Blob([response.data], {type: 'text/plain;charset=utf-8'}),
                headers: response.headers
            };
        }

        vm.changePage = () => {
            select();
        };

        vm.toggleHistory = () => {
            vm.history = !vm.history;
            return select();
        };

        vm.downloadPrescription = (telemonitoringId) => {
            vm.prescriptionDownloads[telemonitoringId] = {
                downloading: true,
                error: null
            };
            PecamOperatorBill.downloadPrescription(telemonitoringId)
                .then(response => ({
                    data: new Blob([response.data], {type: 'application/pdf'}),
                    headers: response.headers
                }))
                .then(DownloadFile.downloadFile)
                .catch(response => {
                    vm.prescriptionDownloads[telemonitoringId].error = response.data.detail;
                })
                .finally(() => {
                    vm.prescriptionDownloads[telemonitoringId].downloading = false;
                });
        };

        vm.openPatient = (id) => {
            if (vm.account.authorities.includes('ROLE_PRACTITIONER')) {
                const url = $state.href('patient-detail', {id});
                $window.open(url, '_blank');
            } else {
                const url = $state.href('patient-detail', {id}, {absolute: true});
                $uibModal.open({
                    templateUrl: 'app/activity/pecam/operator-bill/pecam-operator-bill-patient-preview.html',
                    controller: ['id', 'url', '$uibModalInstance', function (id, url, $uibModalInstance) {
                        const vm = this;
                        vm.id = id;
                        vm.url = url;
                        vm.close = () => $uibModalInstance.close();
                    }],
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'md',
                    resolve: {
                        id: () => id,
                        url: () => url
                    }
                }).result.then(() => {
                    // do nothing
                }, () => {
                    // do nothing
                });
            }
        };

        vm.mark = row => {
            $log.debug('Marking', row.telemonitoringId, row.periods);
            row.isMarking = true;
            let periods;
            if (vm.view === 'MONTHLY') {
                periods = [row.m];
            } else if (vm.view === 'TOTAL') {
                periods = row.periods.map(period => period.m);
            } else {
                throw new Error('Unsupported view');
            }
            const telemonitoringId = row.telemonitoringId;
            const markings = [{ telemonitoringId, periods }];
            PecamOperatorBill.mark(markings).then(response => {
                $log.debug('Marked', row.telemonitoringId, row.periods);
                row.operatorBilled = moment().format('YYYY-MM-DD');
                vm.countPending -= periods.length;
            }).finally(() => {
                row.isMarking = false;
            });
        }

        vm.unmark = row => {
            $log.debug('Unmarking', row.telemonitoringId, row.periods);
            row.isMarking = true;
            let periods;
            if (vm.view === 'MONTHLY') {
                periods = [row.m];
            } else if (vm.view === 'TOTAL') {
                periods = row.periods.map(period => period.m);
            } else {
                throw new Error('Unsupported view');
            }
            const telemonitoringId = row.telemonitoringId;
            const markings = [{ telemonitoringId, periods }];
            PecamOperatorBill.unmark(markings).then(response => {
                $log.debug('Unmarked', row.telemonitoringId, row.periods);
                row.operatorBilled = null;
                vm.countPending += periods.length;
            }).finally(() => {
                row.isMarking = false;
            });
        }

    }
})();
