(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientTherapyProtocolSelectDialogController', PatientTherapyProtocolSelectDialogController);

    PatientTherapyProtocolSelectDialogController.$inject = ['$rootScope', '$scope', '$uibModalInstance', 'Protocol'];

    function PatientTherapyProtocolSelectDialogController($rootScope, $scope, $uibModalInstance, Protocol) {
        const vm = this;

        vm.query = '';
        vm.destroyQueryWatcher = null;
        vm.protocols = null;
        vm.filteredProtocols = null;

        vm.close = close;
        vm.onClickProtocol = onClickProtocol;

        /**
         * Execute on component init
         */
        vm.$onInit = () => {
            loadProtocols();
            initQueryWatcher();
        };

        /**
         * Execute on component destroy
         */
        vm.$onDestroy = () => {
            if(vm.destroyQueryWatcher) {
                vm.destroyQueryWatcher();
            }
        };

        /**
         * Initialize query watcher
         */
        function initQueryWatcher() {
            vm.destroyQueryWatcher =  $scope.$watch("vm.query", (newVal, oldVal) => {
                if (newVal !== oldVal && vm.protocols) {
                    vm.filteredProtocols = filterProtocols(vm.protocols, newVal);
                }
            });
        }

        /**
         * Filter protocols by query
         * @param protocols - list of protocols to filter
         * @param query - query to filter by
         * @return filtered list of protocols
         */
        function filterProtocols(protocols, query) {
            return protocols.filter(protocol => {
                const protocolNameIncludeQuery = protocol.name.toLowerCase().includes(query.toLowerCase());

                const medicationNameIncludeQuery = protocol.medications.some(medication => {
                    return medication.name.toLowerCase().includes(query.toLowerCase());
                });

                return protocolNameIncludeQuery || medicationNameIncludeQuery;
            });
        }

        /**
         * Load protocols list from server
         */
        function loadProtocols() {
            vm.isLoading = true;
            Protocol.query().$promise.then(protocols => {
                vm.protocols = protocols;
                vm.filteredProtocols = filterProtocols(protocols, vm.query);
            }).finally(() => {
                vm.isLoading = false;
            });
        }

        /**
         * Close dialog
         */
        function close() {
            $uibModalInstance.dismiss('cancel');
        }

        /**
         * Handle click on protocol
         * @param protocol - selected protocol
         */
        function onClickProtocol(protocol) {
            $uibModalInstance.close(protocol.medications);
        }

    }
})();
