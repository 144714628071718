(function () {
    'use strict';

    const hospitalFollowUpConsultation = {
        bindings: {
            patient: '=',
        },
        controller: HospitalFollowUpConsultationController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/patient/hospital-follow-up-consultation.html'
    };

    angular
        .module('continuumplatformApp')
        .component('hospitalFollowUpConsultation', hospitalFollowUpConsultation);

    HospitalFollowUpConsultationController.$inject = ['$state', '$uibModal', 'moment', 'Principal', 'Visit', 'PatientTherapy'];

    function HospitalFollowUpConsultationController($state, $uibModal, moment, Principal, Visit, PatientTherapy) {

        const vm = this;

        vm.addVisit = addVisit;
        vm.plan = plan;
        vm.isStartable = Visit.isStartable;
        vm.isResumable = Visit.isResumable;

        vm.isVisitTodoOrInProgress = false;

        vm.$onInit = function () {
            getAccount();
            loadPatientTherapies();
            loadVisits();
        };

        function getAccount() {
            Principal.identity().then(function (account) {
                vm.account = account;
                if (account.practitioner) {
                    vm.isHospitalUser = account.practitioner.healthFacilityType === 'HOSPITAL' && vm.patient.healthFacilityId === account.practitioner.healthFacilityId;
                    vm.isCoordinationFacility = account.practitioner.healthFacilityType === 'COORDINATION';
                    vm.isDoctor = account.practitioner.job === 'J10';
                    vm.isNurse = account.practitioner.job === 'J60';
                }
            });
        }

        function loadPatientTherapies() {
            PatientTherapy.query({
                "patientId.equals": vm.patient.id,
                "status.in": ["S11_VALIDATED", "S20_ACTIVE", "S30_PAUSED"]
            }).$promise.then((patientTherapies) => vm.patientTherapies = patientTherapies);
        }

        function loadVisits() {
            Visit.query({
                "patientId.equals": vm.patient.id,
                "status.in": ['IN_PROGRESS'],
                "type.in": ['NCH_PHONE', 'NCC_PHONE', 'MEDICAL_CONSULT', 'MEDICAL_CONSULT_EXT', 'QUESTIONNAIRE'],
                "sort": "date,desc",
                "size": 1000
            }).$promise.then((visits) => {
                vm.visits = visits.filter((v) => {
                    return (vm.isDoctor && vm.isHospitalUser && ['MEDICAL_CONSULT', 'QUESTIONNAIRE'].includes(v.type) && (vm.isStartable(v) || vm.isResumable(v))) // les consultations médicales à faire ou en cours pour les médecins hospitalier
                        || (vm.isDoctor && !vm.isHospitalUser && ['MEDICAL_CONSULT_EXT', 'QUESTIONNAIRE'].includes(v.type) && (vm.isStartable(v) || vm.isResumable(v))) // les consultations médicales à faire ou en cours pour les médecins non-hospitaliers
                        || (vm.isNurse && vm.isHospitalUser && ['NCH_PHONE', 'QUESTIONNAIRE'].includes(v.type) && (vm.isStartable(v) || vm.isResumable(v))) // les appels entrants ou sortant à faire ou en cours pour les IDEC
                        || (vm.isCoordinationFacility && ['NCC_PHONE', 'QUESTIONNAIRE'].includes(v.type) && (vm.isStartable(v) || vm.isResumable(v))); // les appels entrants ou sortant à faire ou en cours pour les IDEC Continuum+
                });

                vm.medicalConsultInProgress = vm.isDoctor && vm.visits.length;
                vm.nchPhoneInProgress = vm.isNurse && vm.visits.length;

                vm.nccPhoneInProgress = vm.isCoordinationFacility && vm.visits.length;
            });
        }

        function addVisit(visitType) {
            createVisit(visitType).then((visit) => startVisit(visit.id)).catch((error) => {
                // nothing
            });
        }

        function createVisit(visitType) {
            if (!vm.patientTherapies || !vm.patientTherapies.length) {
                return Promise.reject(new Error('Pas de suivi ouvert'));
            }

            let type;
            let steps;

            if (vm.isCoordinationFacility) {
                type = 'NCC_PHONE';
                steps = ['end'];

            } else {

                if (vm.isNurse && vm.isHospitalUser) {
                    type = 'NCH_PHONE';

                } else if (vm.isDoctor && vm.isHospitalUser) {
                    type = 'MEDICAL_CONSULT';

                } else if (vm.isDoctor && !vm.isHospitalUser) {
                    type = 'MEDICAL_CONSULT_EXT';

                } else {
                    type = 'QUESTIONNAIRE';
                }

                if (vm.patient && vm.patient.diseaseCim11Code === '4A00.14') {
                    steps = ['AOH_CRISIS_EVALUATION', 'AOH_SYMPTOMS', 'end'];
                } else {
                    steps = ['measures', 'pain', 'symptoms', 'end'];
                }
            }

            return new Promise((resolve, reject) => {

                let visit = {
                    comment: null,
                    dueDate: null,
                    frequency: null,
                    limitDate: null,
                    nextReminderDate: moment().add(2, 'days').toDate(),
                    patientTherapyId: vm.patientTherapies[0].id,
                    plannedDate: null,
                    reminderDay: null,
                    startDate: null,
                    steps: steps,
                    type: type,
                    adhoc: true
                };

                Visit.createOwn(visit, (data) => {
                    visit.id = data.id;
                    resolve(visit);
                }, (error) => {
                    // nothing;
                });
            });
        }

        function startVisit(visitId) {
            $state.go('visit-wizard', {id: visitId});
        }

        function plan(visit) {
            $uibModal.open({
                templateUrl: 'app/activity/visit/visit-plan-dialog.html',
                controller: 'VisitPlanDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    visit: ['Visit', function (Visit) {
                        return Visit.get({id: visit.id}).$promise;
                    }],
                }
            }).result.then(() => {
                loadVisits();
            }, () => {
                // do nothing
            });
        }
    }

})();
