(function () {
    'use strict';

    const alertCountLegacyBlock = {
        bindings: {
            filters: '=',
            height: '=',
        },
        controller: AlertCountLegacyBlockController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/alert-count-legacy/alert-count-legacy-block.html'
    };

    angular
        .module('continuumplatformApp')
        .component('alertCountLegacyBlock', alertCountLegacyBlock);

    AlertCountLegacyBlockController.$inject = ['$log', '$scope', 'DashboardService'];

    function AlertCountLegacyBlockController($log, $scope, DashboardService) {
        const vm = this;

        vm.countByLevel = null;

        /**
         * Initialize the component.
         */
        vm.$onInit = function () {

            count();

            vm.unsubscribeWatchFilter = $scope.$watch('vm.filters', function (newVal, oldVal) {
                if (newVal !== oldVal) {
                    count();
                }
            }, true);

        };

        /**
         * Call when component is destroyed.
         */
        vm.$onDestroy = function () {
            if (vm.unsubscribeWatchFilter) {
                vm.unsubscribeWatchFilter();
            }
        };

        function count() {
            vm.countByLevel = null;
            vm.loading = true;
            DashboardService.loadVisitAlertCountLegacy(vm.filters)
                .then(result => vm.countByLevel = result)
                .catch(error => $log.error("Unable to load alert count", error))
                .finally(() => vm.loading = false);
        }

    }

})();
