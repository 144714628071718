(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .directive('validPhone', ['$log', validPhone]);

    function validPhone($log) {

        var phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, modelCtrl) {

                let mobileOnly = attrs.validPhoneType && attrs.validPhoneType.toLowerCase() === 'mobile';

                function validate(modelValue, viewValue) {

                    $log.debug("validate", modelValue, viewValue);

                    if (modelCtrl.$isEmpty(modelValue)) {
                        return true;
                    }

                    try {
                        if (attrs.countryCode && attrs.countryCode.length === 2) {
                            var parsedNumber = phoneUtil.parse(modelValue, attrs.countryCode);
                            if(mobileOnly) {
                                return phoneUtil.getNumberType(parsedNumber) === libphonenumber.PhoneNumberType.MOBILE;
                            }
                            return phoneUtil.isValidNumber(parsedNumber);
                        } else {
                            // TODO remove
                            var parsedNumber = phoneUtil.parse('+' + modelValue);
                            var isvalidnum = phoneUtil.isValidNumber(parsedNumber);
                            if (isvalidnum) {
                                if (typeof attrs.countryCode != 'undefined') {
                                    attrs.countryCode = phoneUtil.getRegionCodeForNumber(parsedNumber);
                                    phoneUtil.format(parsedNumber, 2);
                                }
                                return true;
                            } else {
                                return false;
                            }
                        }
                    } catch (err) {
                        $log.debug(err);
                        return false;
                    }

                }

                function parse(value) {
                    $log.debug("parse", value);

                    if (!value)
                        return value;

                    value = value.replace(/([^0-9|+])/g, '');

                    var formatter = new libphonenumber.AsYouTypeFormatter(attrs.countryCode);
                    var formatted;
                    for (var i = 0; i < value.length; i++) {
                        formatted = formatter.inputDigit(value[i]);
                    }

                    $log.debug("parser-formatted", formatted);
                    modelCtrl.$setViewValue(formatted);
                    modelCtrl.$render();

                    try {
                        var number = phoneUtil.parse(value, attrs.countryCode);
                        var e164 = phoneUtil.format(number, 0);
                        $log.debug("parser-store-e164", e164);
                        return e164;
                    } catch (err) {
                        $log.debug("parser-store-asis", value);
                        return value;
                    }

                }

                function format(value) {
                    $log.debug("format", value);

                    if (!value)
                        return value;

                    var number = phoneUtil.parse(value, attrs.countryCode);

                    return phoneUtil.format(number, 2);
                }

                attrs.$observe('countryCode', function () {
                    modelCtrl.$validate();
                    if (typeof attrs.samplePlaceholder != 'undefined') {
                        try {
                            var exnumber = phoneUtil.getExampleNumberForType(attrs.countryCode, attrs.samplePlaceholder);
                            attrs.$set('placeholder', phoneUtil.format(exnumber, 2));
                        } catch (err) {
                            attrs.$set('placeholder', '');
                        }
                    }
                });

                modelCtrl.$parsers.push(parse);
                modelCtrl.$formatters.push(format);
                modelCtrl.$validators.phone = validate;
            }
        }
    }

})();
