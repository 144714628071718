(function() {
    'use strict';
    angular
        .module('continuum-alert')
        .factory('VisitAlerts', VisitAlerts);

    VisitAlerts.$inject = ['$http'];

    function VisitAlerts($http) {

        return { countByLevel, query };

        function countByLevel(params) {
            return $http.get('api/visit-alerts/count-by-level', {params});
        }

        function query(params) {
            return $http.get('api/visit-alerts', {params});
        }

    }

})();
