(function () {
    'use strict';

    const adherenceResultIcon = {
        bindings: {
            score: '='
        },
        controller: AdherenceResultIconController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/adherence-report/adherence-result-icon.html'
    };

    angular
        .module('continuumplatformApp')
        .component("adherenceResultIcon", adherenceResultIcon);

    AdherenceResultIconController.$inject = ['AdherenceReport'];

    function AdherenceResultIconController(AdherenceReport) {
        const vm = this;

        vm.result = null;

        vm.$onInit = () => {
            vm.result = AdherenceReport.computeResult(vm.score);
        };
    }
})();
