(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('AdherenceReport', AdherenceReport);

    AdherenceReport.$inject = ['$resource'];

    function AdherenceReport($resource) {
        const resourceUrl = 'api/adherence-reports/:id';

        const service = $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'history': {
                method: 'GET',
                url: 'api/adherence-reports/history',
                isArray: true
            }
        });

        service.computeResult = score => {
            if(score === 0) {
                return 0;
            }
            if(score >= 1 && score <= 2) {
                return 1;
            }
            if(score >= 3 && score <= 6) {
                return 3;
            }
            return null;
        }

        return service;
    }
})();
