(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PatientSearchController', PatientSearchController);

    PatientSearchController.$inject = ['$log', '$scope', '$state', '_', 'filters', 'PatientSearch', 'Patient', 'PatientTherapy', 'Coordination', 'Principal'];

    function PatientSearchController($log, $scope, $state, _, filters, PatientSearch, Patient, PatientTherapy, Coordination, Principal) {

        const vm = this;

        // conservation de l'ordre et écrasement des requêtes successives
        let currentRequest = null;
        let stack = [];
        vm.isExecuting = false;

        vm.predicate = null;
        vm.ascending = true;

        vm.isSelectingPatient = false;
        vm.importPatientError = null;

        vm.patients = null;
        vm.total = null;

        vm.hasSearchByCriteriaAuthority = null;
        vm.filters = filters;
        if (vm.filters.internalId) {
            vm.locked = true;
            searchPatient();
        } else {
            vm.locked = false;

            Principal.hasAuthority('PATIENT_SEARCH_CONNECTOR_BY_CRITERIA').then(hasAuthority => {
                vm.hasSearchByCriteriaAuthority = hasAuthority;

                if (vm.hasSearchByCriteriaAuthority) {
                    $scope.$watchGroup(['vm.filters.familyName', 'vm.filters.givenName', 'vm.filters.birthDate'], (newValues, oldValues) => {
                        if (_.isEqual(newValues, oldValues)) {
                            return;
                        }
                        const familyName = newValues[0];
                        const givenName = newValues[1];
                        const birthDate = newValues[2];
                        if (!birthDate && !familyName && !givenName) {
                            if (!vm.filters.ins && !vm.filters.internalId) {
                                queue(clear);
                            }
                        } else {
                            vm.filters.ins = null;
                            vm.filters.internalId = null;
                            vm.searchPatient();
                        }
                    });
                }
            });

            $scope.$watch('vm.filters.ins', (newVal, oldVal) => {
                if (newVal) {
                    vm.filters.internalId = null;
                    vm.searchPatient();
                } else if (oldVal && !vm.filters.familyName && !vm.filters.givenName && !vm.filters.birthDate && !vm.filters.internalId) {
                    queue(clear);
                }
            });

            $scope.$watch('vm.filters.internalId', (newVal, oldVal) => {
                if (!newVal && oldVal && !vm.filters.familyName && !vm.filters.givenName && !vm.filters.birthDate && !vm.filters.ins) {
                    queue(clear);
                }
            });
        }

        vm.searchPatient = searchPatient;

        function searchPatient() {

            if(vm.filters.ins && vm.filters.internalId) {
                $log.error('Un seul identifiant peut être saisi');
                return;
            }

            if (vm.filters.ins || vm.filters.internalId) {
                vm.filters.familyName = null;
                vm.filters.givenName = null;
                vm.filters.birthDate = null;
                queue(PatientSearch.search, angular.toJson({identifier: vm.filters.ins || vm.filters.internalId}));
            } else if (vm.filters.familyName || vm.filters.givenName || vm.filters.birthDate) {
                queue(PatientSearch.search, angular.toJson(vm.filters));
            } else {
                queue(clear);
            }
        }

        vm.onSelectPatient = (patient) => {

            if (vm.isSelectingPatient) {
                return;
            }

            if (patient.deceased) {
                return;
            }

            vm.isSelectingPatient = true;
            vm.importPatientError = null;
            vm.importPatientErrorMessage = null;

            if (patient.id) {

                Patient.query({
                    view: 'full',
                    'id.equals': patient.id,
                }).$promise
                    .then(foundPatients => {
                        if (foundPatients.length === 0) {
                            return Promise.reject('Vous n\'êtes pas autorisé à accéder à ce dossier patient.');
                        }

                        const foundPatient = foundPatients[0];

                        if (foundPatient.patientTherapyId === null) {
                            return Promise.resolve([null, null]);
                        }

                        // TODO add patientTherapy to PatientFullDTO
                        return PatientTherapy.get({ id: foundPatient.patientTherapyId })
                            .$promise
                            .then(patientTherapy => Coordination.getNextContractLines(patientTherapy.id)
                                .then(nextContractLines => [patientTherapy, nextContractLines]));
                    })
                    .then(([patientTherapy, nextContractLines]) =>
                        Coordination.newRequest(patient.id, patientTherapy, nextContractLines).then((result) => {
                            vm.isSelectingPatient = !!result;
                        }))
                    .catch(error => {
                        $log.error('Unable to select patient', error);
                        vm.isSelectingPatient = false;
                        vm.importPatientError = true;
                        vm.importPatientErrorMessage = error;
                    });
            } else {
                PatientSearch.importPatient(patient.connectorId)
                    .then((response) => {
                        $state.go('patient-detail.request-therapy', { id: response.data });
                    })
                    .catch(response => {
                        $log.error('Unable to import patient', response);
                        vm.isSelectingPatient = false;
                        vm.importPatientError = true;
                    });
            }
        };

        vm.sort = () => {
            vm.patients = vm.patients.sort((p1, p2) => {
                if (vm.ascending) {
                    return p1[vm.predicate].localeCompare(p2[vm.predicate]);
                } else {
                    return p2[vm.predicate].localeCompare(p1[vm.predicate]);
                }
            });
        };

        function clear() {
            vm.patients = null;
            vm.total = null;
        }

        function processResponse(data) {
            vm.patients = data.content;
            vm.total = data.total;
        }

        function queue(fn, data) {
            stack = [];
            stack.push({fn: abort});
            stack.push({fn, data});
            execute();
        }

        function abort() {
            if(!(currentRequest && currentRequest.abort())) {
                execute();
            }
        }

        function execute() {

            if (stack.length === 0) {
                $log.debug('Nothing to execute, skipping.');
                vm.isExecuting = false;
                return;
            }

            vm.isExecuting = true;

            const request = stack.shift();

            if(request.fn === clear) {
                clear();
                execute();
            } else if(request.fn === abort) {
                abort();
            } else {
                $log.debug("Searching...");
                vm.patients = null;
                vm.total = null;
                (currentRequest = request.fn(angular.fromJson(request.data)))
                    .then(processResponse)
                    .finally(execute);
            }
        }

    }
})();
