(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('VisitController', VisitController);

    VisitController.$inject = ['$log', '$state', '$rootScope', '$scope', '$stateParams', '$uibModal', 'Visit', 'PatientTherapy', 'ParseLinks', 'paginationConstants', 'pagingParams', 'moment', 'Principal', 'DownloadFile', 'Practitioner', 'HealthFacility'];

    function VisitController($log, $state, $rootScope, $scope, $stateParams, $uibModal, Visit, PatientTherapy, ParseLinks, paginationConstants, pagingParams, moment, Principal, DownloadFile, Practitioner, HealthFacility) {

        const vm = this;

        vm.savedFilters = {};
        vm.filters = $stateParams.filters;

        vm.page = pagingParams.page;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        vm.isStartable = Visit.isStartable;
        vm.isResumable = Visit.isResumable;
        vm.today = moment().startOf('day').toDate();
        vm.downloadingVisitReport = {};

        vm.downloadVisitReport = downloadVisitReport;
        vm.plan = plan;
        vm.applyPagingAndSorting = applyPagingAndSorting;
        vm.refreshContentAndFilters = refreshContentAndFilters;
        vm.refreshContent = refreshContent;

        vm.$onInit = () => {

            getAccount().then(() => {
                if (vm.isCoordination) {
                    loadUnicorns();
                }

                loadHealthFacility()
                    .then(loadTrials)
                    .then(loadTherapyType)
                    .then(loadAll);
            });

            vm.unsubscribeVisit = $rootScope.$on('continuumplatformApp:visitUpdate', loadVisits);

            vm.unwatchFiltersStatus = $scope.$watchCollection("vm.filters.status", (oldValue, newValue) => {
                const oldSorted = oldValue.slice().sort();
                if (newValue.length === oldValue.length
                    && newValue.slice().sort().every((value, index) => value === oldSorted[index])) {
                    return;
                }
                refreshContent();
            });
        };

        vm.$onDestroy = () => {
            if (vm.unsubscribeVisit) {
                vm.unsubscribeVisit();
            }
            if (vm.unwatchFiltersStatus) {
                vm.unwatchFiltersStatus();
            }
            if (vm.unwatchTrial) {
                vm.unwatchTrial();
            }
            if (vm.unwatchTherapyType) {
                vm.unwatchTherapyType();
            }
            if (vm.unwatchFeasibleByPractitionerId) {
                vm.unwatchFeasibleByPractitionerId();
            }
        };

        function loadHealthFacility() {
            return HealthFacility.get({
                view: 'summary',
                id: vm.account.practitioner.healthFacilityId,
            }).$promise.then(facility => vm.facility = facility);
        }

        function getAccount() {
            return Principal.identity().then(account => {

                if (!account.practitioner || !account.practitioner.healthFacilityId) {
                    $log.error('Only practitioners in facilities can use VisitController');
                    $state.go('accessdenied');
                    return;
                }

                vm.account = account;
                vm.isAdmin = account.authorities.includes('ROLE_ADMIN');
                vm.isCoordination = account.practitioner.healthFacilityType === 'COORDINATION';
                vm.isHospitalUser = account.practitioner.healthFacilityType === 'HOSPITAL';

                vm.practitioner = account.practitioner;

                vm.isPharmacist = vm.practitioner.job === 'J21';
                const isHospitalPharmacist = vm.isHospitalUser && vm.practitioner.job === 'J21';
                const isHospitalPharmacyPreparer = vm.isHospitalUser && vm.practitioner.job === 'J38';
                vm.isNurse = vm.practitioner.job === 'J60';
                const isCoordinationNurse = vm.practitioner.healthFacilityType === 'HOSPITAL' && vm.practitioner.job === 'J60';

                if (vm.filters.feasibleByMe === null || angular.isUndefined(vm.filters.feasibleByMe)) {
                    vm.filters.feasibleByMe = !vm.isAdmin && !isHospitalPharmacist && !isCoordinationNurse && !isHospitalPharmacyPreparer;
                }

                vm.visitTypes = Visit.visitTypes;
                vm.visitStatuses = Visit.visitStatuses;
            });
        }

        vm.parseDate = function(str) {
            return new Date(str);
        };

        function loadAll() {
            loadFilterPrograms();
            loadFilterTherapies();
            loadVisits();
        }

        function loadTrials() {
            vm.enableTrialFilter = vm.facility.trials.length > 0;
            if (vm.enableTrialFilter) {
                vm.trials = vm.facility.trials;
                vm.savedFilters.trial = vm.account.getPractitionerFilter('TRIAL');
                vm.unwatchTrial = $scope.$watch('vm.savedFilters.trial', onChangeTrial);
            }
        }

        function onChangeTrial(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            Principal.setPractitionerFilter('TRIAL', newVal);
            refreshContentAndFilters();
        }

        function loadTherapyType() {
            vm.savedFilters.therapyType = vm.account.getPractitionerFilter('THERAPY_TYPE');
            vm.unwatchTherapyType = $scope.$watch('vm.savedFilters.therapyType', onChangeTherapyType);
        }

        function onChangeTherapyType(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            Principal.setPractitionerFilter('THERAPY_TYPE', newVal);
            refreshContentAndFilters();
        }

        function refreshContentAndFilters() {
            resetPage().then(loadAll);
        }

        function refreshContent() {
            resetPage().then(loadVisits);
        }

        function resetPage() {
            vm.page = 1;
            return toPage();
        }

        function loadUnicorns() {
            if (angular.isUndefined(vm.filters.patientTherapyAssigneeId)) {
                vm.filters.patientTherapyAssigneeId = vm.account.practitioner.id;
            }
            Practitioner.query({
                'healthFacilityId.equals': vm.account.practitioner.healthFacilityId,
                size: 1000,
                sort: ['lastName,asc'],
            }).$promise.then((unicorns) => {
                vm.unicorns = unicorns;
                vm.unwatchFeasibleByPractitionerId = $scope.$watch('vm.filters.patientTherapyAssigneeId', (newVal, oldVal) => {
                    if (angular.equals(newVal, oldVal)) {
                        return;
                    }
                    refreshContentAndFilters();
                });
            });
        }

        function loadFilterPrograms() {
            Visit.queryDistinctPrograms({
                "patientTherapyAssigneeId.equals": vm.filters.patientTherapyAssigneeId || undefined,
                "trial.equals": vm.savedFilters.trial || undefined,
                "hdj.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hdj' : undefined,
                "hah.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hah' : undefined,
            }, function(result) {
                vm.programs = result;
            });
        }

        function loadFilterTherapies() {
            Visit.queryDistinctTherapies({
                "patientTherapyAssigneeId.equals": vm.filters.patientTherapyAssigneeId || undefined,
                "programId.equals": vm.filters.programId === null ? undefined : vm.filters.programId,
                "type.equals": vm.filters.type,
                "trial.equals": vm.savedFilters.trial || undefined,
                "hdj.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hdj' : undefined,
                "hah.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hah' : undefined,
            }, function(result) {
                vm.therapies = result;
            });
        }

        function loadVisits() {

            vm.reverse = !(vm.filters.status.length === 0
                || vm.filters.status.indexOf('DONE') !== -1
                || vm.filters.status.indexOf('CANCELED') !== -1
                || vm.filters.status.indexOf('EXPIRED') !== -1);

            vm.isLoading = true;
            vm.error = false;
            vm.visits = null;
            $log.debug('Loading visits page', vm.page);
            Visit.query({
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                feasibleByPractitionerId: vm.filters.feasibleByMe ? vm.practitioner.id : undefined,
                "patientTherapyAssigneeId.equals": vm.filters.patientTherapyAssigneeId || undefined,
                "type.equals": vm.filters.type,
                "status.in": vm.filters.status,
                "query": vm.filters.query,
                "therapyId.equals": vm.filters.therapyId,
                "programId.equals": vm.filters.programId,
                "trial.equals": vm.savedFilters.trial || undefined,
                "hdj.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hdj' : undefined,
                "hah.equals": vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hah' : undefined,
                pre: vm.filters.pre,
                telemonitoring: vm.filters.telemonitoring,
            }, onSuccess, () => {}).$promise.catch(error => {
                $log.error('Unable to load visits', error);
                vm.error = true;
            }).finally(() => {
                vm.isLoading = false;
            });

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.visits = data;
            }

        }

        function applyPagingAndSorting() {
            $log.debug('Paging and sorting')
            toPage().then(loadVisits);
        }

        function toPage() {
            return $state.go($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.ascending ? 'asc' : 'desc'),
                filters: vm.filters
            });
        }

        function plan(visit) {
            $uibModal.open({
                templateUrl: 'app/activity/visit/visit-plan-dialog.html',
                controller: 'VisitPlanDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    visit: ['Visit', function(Visit) {
                        return Visit.get({ id: visit.id }).$promise;
                    }],
                }
            }).result.then(() => {
                // do nothing
            }, () => {
                // do nothing
            });
        }

        function downloadVisitReport(event, visitId) {
            // pour éviter les téléchargements multiples
            if (vm.downloadingVisitReport[visitId]) {
                event.preventDefault();
                return;
            }
            vm.downloadingVisitReport[visitId] = true;

            vm.isExportingPdf = true;
            Visit.exportPdf({
                id: visitId
            }).$promise
                .then(DownloadFile.downloadFile)
                .finally(function() {
                    delete vm.downloadingVisitReport[visitId];
                });
        }


    }
})();
