(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('MedicationModalController', MedicationModalController);

    MedicationModalController.$inject = [ '$uibModalInstance', '$state', 'medication', 'MedicationVariant', 'MedicationInfo'];

    function MedicationModalController($uibModalInstance, $state, medication, MedicationVariant, MedicationInfo) {
        let vm = this;

        vm.medication = medication;

        vm.programContinuum = vm.medication.program ? vm.medication.program.filter((p) => p.programContinuum) : [];
        vm.programListHospital = vm.medication.program ? vm.medication.program.filter((p) => !p.programContinuum) : [];

        vm.close = close;
        vm.therapyRequest = therapyRequest;

        if(medication.id !== -1) {
            loadMedicationVariant();
            loadMedicationInfo();
        }

        function loadMedicationVariant() {
            MedicationVariant.query({
                "medicationId.equals": vm.medication.id
            }, (variants) => {
                vm.medicationVariants = variants;
                vm.medicationVariants.sort((v1 ,v2) => v1.name.toLocaleUpperCase().localeCompare(v2.name.toLocaleUpperCase()));
            });
        }

        function loadMedicationInfo() {
            MedicationInfo.query({
                "medicationId.equals": medication.id
            }, function (medicationInfo) {
                vm.euSpcMedicationInfo = [];
                vm.proMedicationInfo = [];
                vm.patientMedicationInfo = [];

                vm.medicationInfo = medicationInfo;

                medicationInfo.forEach((info) => {
                    switch (info.type) {
                    case "EU_SPC":
                        vm.euSpcMedicationInfo.push(info);
                        break;

                    case "PRO_INFO" :
                        vm.proMedicationInfo.push(info);
                        break;

                    case "PATIENT_INFO" :
                        vm.patientMedicationInfo.push(info);
                        break;
                    }
                });

            });
        }

        function close() {
            $uibModalInstance.dismiss('cancel');
        }

        function therapyRequest() {
            vm.close();
            $state.go('patient-therapy.request');
        }

    }
})();
