(function () {
    'use strict';

    var practitionerFacilityPanel = {
        bindings: {
            practitioner: '='
        },
        controller: PractitionerFacilityPanelController,
        controllerAs: 'vm',
        templateUrl: 'app/entities/practitioner/practitioner-facility-panel.html'
    };

    angular
        .module('continuumplatformApp')
        .component('practitionerFacilityPanel', practitionerFacilityPanel);

    PractitionerFacilityPanelController.$inject = ['$rootScope', '$state', '$uibModal', 'Practitioner'];

    function PractitionerFacilityPanelController($rootScope, $state, $uibModal, Practitioner) {

        var vm = this;

        vm.selectHealthFacility = selectHealthFacility;
        vm.clearHealthFacility = clearHealthFacility;

        function openFacilitySelection(healthFacilityType) {
            return $uibModal.open({
                templateUrl: 'app/entities/health-facility/health-facility-selection.html',
                controller: 'HealthFacilitySelectionController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    pagingParams: [function () {
                        return {
                            predicate: 'name',
                            ascending: true
                        };
                    }],
                    search: [function () {
                        return {
                            name: null,
                            address: null,
                            postalCode: null,
                            city: null
                        };
                    }],
                    healthFacilityType: [function () {
                        return healthFacilityType;
                    }],
                    parentHealthFacilityId: [function () {
                        return null;
                    }]
                }
            });
        }

        function selectHealthFacility() {
            openFacilitySelection().result.then(updateHealthFacility);
        }

        function clearHealthFacility() {
            updateHealthFacility(null);
        }

        function updateHealthFacility(id) {
            Practitioner.updateHealthFacility({id: vm.practitioner.id}, id, emitUpdate);
        }

        function emitUpdate() {
            $rootScope.$emit('continuumplatformApp:practitionerUpdate', vm.practitioner.id);
        }

    }

})();
