(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('Account', Account);

    Account.$inject = ['$resource', 'DateUtils'];

    function Account($resource, DateUtils) {
        const service = $resource('api/account', {}, {
            'get': {
                method: 'GET',
                params: {},
                isArray: false,
                interceptor: {
                    response: function (response) {
                        // expose response
                        return response;
                    }
                },
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        if (data.practitioner) {
                            data.practitioner.birthDate = DateUtils.convertLocalDateFromServer(data.practitioner.birthDate);
                        }
                        if (data.patient) {
                            data.patient.birthDate = DateUtils.convertLocalDateFromServer(data.patient.birthDate);
                        }
                    }
                    return data;
                }
            },
            'getNotificationSettings': {
                url: 'api/account/notification-settings',
                method: 'GET'
            },
            'updateNotificationSettings': {
                url: 'api/account/notification-settings',
                method: 'PUT'
            },
            'acceptTerms': {
                url: 'api/account/accept-terms',
                method: 'POST'
            }
        });

        delete service.prototype.$query;
        delete service.prototype.$save;
        delete service.prototype.$delete;
        delete service.prototype.$remove;

        return service;
    }
})();
