(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('visit-pending', {
                parent: 'patients',
                url: '/pending',
                data: {
                    authorities: ['ROLE_PRACTITIONER'],
                    pageTitle: 'global.menu.pending'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/visit/pending/pending.html',
                        controller: 'PendingVisitsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    filters: {
                        value: {
                            fromDate: null
                        }
                    },
                    options: {
                        value: {
                            mode: 'DAY'
                        }
                    },
                },
                resolve: {
                    options: ['$transition$', $transition$ => $transition$.params().options],
                    filters: ['$transition$', $transition$ => $transition$.params().filters],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', ($translate, $translatePartialLoader) => {
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('visitType');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
