(() => {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('PecamOperatorBill', PecamOperatorBill);

    PecamOperatorBill.$inject = ['$rootScope', '$http'];

    function PecamOperatorBill($rootScope, $http) {

        return { count, selectMonthly, selectTotal, exportCsv, mark, unmark, downloadPrescription };

        function count(history) {
            return $http.get('api/pecam-operator-bill/count', {
                params: {history}
            });
        }

        function selectMonthly(history, page, size) {
            return $http.get('api/pecam-operator-bill/select-monthly', {
                params: {history, page, size}
            });
        }

        function selectTotal() {
            return $http.get('api/pecam-operator-bill/select-total');
        }

        function exportCsv() {
            return $http.get('api/pecam-operator-bill/export');
        }

        function mark(markings) {
            return $http.post('api/pecam-operator-bill/mark', markings);
        }

        function unmark(markings) {
            return $http.post('api/pecam-operator-bill/unmark', markings);
        }

        function downloadPrescription(telemonitoringId) {
            return $http.get('api/pecam-operator-bill/prescription/' + telemonitoringId);
        }

    }

})();
