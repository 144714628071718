(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('facility-planning', {
                parent: 'patients',
                url: '/facility-planning',
                data: {
                    authorities: ['ROLE_PRACTITIONER'],
                    pageTitle: 'continuumplatformApp.visit.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/activity/visit/facility-planning/facility-planning.html',
                        controller: 'FacilityPlanningController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    filters: {
                        value: {
                            status: {
                                TODO: true,
                                IN_PROGRESS: true,
                                DONE: false,
                                EXPIRED: false,
                            },
                            feasibleByPractitionerId: undefined,
                            visitType: null,
                        }
                    }
                },
                resolve: {
                    filters: ['$transition$', $transition$ => $transition$.params().filters],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('visit');
                        $translatePartialLoader.addPart('patient');
                        $translatePartialLoader.addPart('patientStatus');
                        $translatePartialLoader.addPart('patientTherapyStatus');
                        $translatePartialLoader.addPart('visitStatus');
                        $translatePartialLoader.addPart('visitType');
                        $translatePartialLoader.addPart('entity-audit');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
