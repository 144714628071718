(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .factory('Principal', Principal);

    Principal.$inject = ['$log', '$q', 'Account', 'Practitioner'];

    function Principal($log, $q, Account, Practitioner) {
        let _identity,
            _offline,
            _authenticated = false;

        return {
            authenticate,
            hasAnyAuthority,
            hasAuthority,
            identity,
            isAuthenticated,
            isOffline,
            setPractitionerFilter,
            removePractitionerFilter,
        };

        function authenticate (identity) {
            _identity = identity;
            _authenticated = identity !== null;
        }

        function hasAnyAuthority (authorities) {
            if (!_authenticated || !_identity || !_identity.authorities) {
                return false;
            }

            for (var i = 0; i < authorities.length; i++) {
                if (_identity.authorities.indexOf(authorities[i]) !== -1) {
                    return true;
                }
            }

            return false;
        }

        function hasAuthority (authority) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function(_id) {
                return _id.authorities && _id.authorities.indexOf(authority) !== -1;
            }, function(){
                return false;
            });
        }

        function identity (force) {

            var deferred = $q.defer();

            if (force === true) {
                _identity = undefined;
            }

            // check and see if we have retrieved the identity data from the server.
            // if we have, reuse it by immediately resolving
            if (angular.isDefined(_identity)) {
                deferred.resolve(_identity);

                return deferred.promise;
            }

            // retrieve the identity data from the server, update the identity object, and then resolve.
            Account.get().$promise
                .then(getAccountThen)
                .catch(getAccountCatch);

            return deferred.promise;

            function getAccountThen (account) {
                _identity = account.data;
                _identity.getPractitionerFilter = (filter) => {
                    if (_identity && _identity.practitioner && _identity.practitioner.settings && _identity.practitioner.settings.filters)
                        return _identity.practitioner.settings.filters[filter];
                    return undefined;
                };
                _authenticated = true;
                _offline = false;
                deferred.resolve(_identity);
            }

            function getAccountCatch (response) {
                _identity = null;
                _authenticated = false;
                _offline = response.status === -1;
                deferred.resolve(_identity);
            }
        }

        function isAuthenticated () {
            return _authenticated;
        }

        function isOffline () {
            return _offline;
        }

        function setPractitionerFilter(filter, value) {
            if(!value) {
                removePractitionerFilter(filter);
                return;
            }
            Practitioner.setFilter({ filter }, value)
                .$promise
                .then(() => {
                    if (_identity && _identity.practitioner.settings && _identity.practitioner.settings.filters)
                        _identity.practitioner.settings.filters[filter] = value;
                })
                .catch($log.error);
        }

        function removePractitionerFilter(filter) {
            Practitioner.removeFilter({ filter })
                .$promise
                .then(() => {
                    if (_identity && _identity.practitioner.settings && _identity.practitioner.settings.filters)
                        delete _identity.practitioner.settings.filters[filter];
                })
                .catch($log.error);
        }
    }
})();
