(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('HealthFacilitySelectionController', HealthFacilitySelectionController);

    HealthFacilitySelectionController.$inject = ['$scope', 'search', 'healthFacilityType', 'parentHealthFacilityId', 'HealthFacility', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', '$uibModalInstance'];

    function HealthFacilitySelectionController($scope, search, healthFacilityType, parentHealthFacilityId, HealthFacility, ParseLinks, AlertService, paginationConstants, pagingParams, $uibModalInstance) {

        var vm = this;

        vm.predicate = pagingParams.predicate;
        vm.ascending = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = 100;
        vm.page = 1;

        vm.loadAll = loadAll;
        vm.close = close;
        vm.select = select;
        vm.clearSearch = clearSearch;
        vm.reapplySearch = reapplySearch;
        vm.nextPage = nextPage;

        vm.initialSearch = search && angular.copy(search);
        vm.search = search || getDefaultFilter();
        vm.parentHealthFacilityId = parentHealthFacilityId;

        $scope.$watch('vm.search', function () {
            vm.page = 1;
            loadAll(false)
        }, true);

        function loadAll(increment) {
            vm.isLoading = true;
            vm.increment = increment;
            HealthFacility.query({
                view: 'summary',
                page: vm.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                "type.equals": healthFacilityType,
                "name.containsNormalized": vm.search.name ? vm.search.name : undefined,
                "address.contains": vm.search.address ? vm.search.address : undefined,
                "postalCode.startsWith": vm.search.postalCode ? vm.search.postalCode : undefined,
                "city.containsNormalized": vm.search.city ? vm.search.city : undefined,
                "healthFacilityId.equals": parentHealthFacilityId ? parentHealthFacilityId : undefined,
                "healthFacilityId.specified": parentHealthFacilityId ? undefined : false,
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.ascending ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.isLoading = false;
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                if (vm.healthFacilities && vm.increment)
                    vm.healthFacilities.push.apply(vm.healthFacilities, data);
                else
                    vm.healthFacilities = data;
                vm.increment = false;
            }

            function onError(error) {
                vm.isLoading = false;
                AlertService.error(error.data.message);
            }
        }

        function transition() {
            vm.page = 1;
            loadAll(false);
        }

        function close() {
            $uibModalInstance.dismiss('cancel');
        }

        function select(healthFacilityId) {
            $uibModalInstance.close(healthFacilityId);
        }

        function getDefaultFilter() {
            return {
                name: null,
                address: null,
                postalCode: null,
                city: null
            };
        }

        function clearSearch() {
            vm.search = getDefaultFilter();
            vm.healthFacilities = null;
        }

        function reapplySearch() {
            vm.search = vm.initialSearch;
            vm.healthFacilities = null;
        }

        function nextPage() {
            vm.page++;
            loadAll(true);
        }
    }
})();
