(function () {
    'use strict';

    const alertCountLegacyBlockItem = {
        bindings: {
            filters: '=',
            grade: '=',
            alertNumber: '=',
        },
        controller: AlertCountLegacyBlockItemController,
        controllerAs: 'vm',
        templateUrl: 'app/dashboard/alert-count-legacy/alert-count-legacy-block-item.html'
    };

    angular
        .module('continuumplatformApp')
        .component('alertCountLegacyBlockItem', alertCountLegacyBlockItem);

    AlertCountLegacyBlockItemController.$inject = [];

    function AlertCountLegacyBlockItemController() {

        const vm = this;

        vm.$onInit = () => {

        };

    }

})();
