
(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .controller('DocumentsController', DocumentsController);

    DocumentsController.$inject = ['$scope', '$uibModal', '_', 'MedicationInfo', 'ContractLine', 'Medication', 'ContractualTreatmentGroup'];

    function DocumentsController($scope, $uibModal, _, MedicationInfo, ContractLine, Medication, ContractualTreatmentGroup) {

        const vm = this;

        vm.diseaseListAkodom = [];
        vm.diseaseListAkopharma = [];
        vm.diseaseListAkopro = [];
        vm.diseaseListPicto = [];

        vm.medicationSearchList = [];
        vm.medicationMap = new Map();
        vm.isLoading = true;

        vm.searchMedication = searchMedication;
        vm.onClickMedication = onClickMedication;
        vm.onBlurSearchInput = onBlurSearchInput;
        vm.onPressEnter = onPressEnter;
        vm.onClickSearch = onClickSearch;


        $scope.$watch('vm.currentSearch', () => {vm.searchMedication();});

        loadMedications();

        function loadMedications() {
            Medication.query({size: 1000,}, (medicationList) => {
                vm.medicationMap = new Map();
                medicationList.forEach((m) => vm.medicationMap.set(m.id, m));
                vm.medicationMap.set(-1, {
                    id: -1,
                    name: 'Tous les médicaments'
                });
                loadContractualTreatmentGroup();
            });
        }

        function loadContractualTreatmentGroup() {
            ContractualTreatmentGroup.query({}, function (contractualTreatmentGroupList) {
                vm.contractualTreatmentGroupMap = new Map();
                contractualTreatmentGroupList.forEach( (ctg) => vm.contractualTreatmentGroupMap.set(ctg.id, ctg));
                loadContractLine();
            });
        }

        function loadContractLine() {
            ContractLine.query({
                size: 9999,
                activeOn: moment().format('YYYY-MM-DD')
            }, (contractList) => onloadContractLineSuccess(contractList));
        }

        function onloadContractLineSuccess(contractList) {
            const diseaseMap = new Map();
            contractList.forEach( (contract) => {
                let disease = diseaseMap.get(contract.treatmentGroupId + '_' + contract.programName);
                if (disease) {
                    disease.program.push({
                        programName: contract.programName,
                        programCode: contract.programCode,
                        programContinuum: contract.programContinuum,
                        programDescription: contract.programDescription,
                        programId: contract.programId,
                        programPro: contract.programPro,
                    });
                    // dédoublonnage
                    disease.program = [...new Map(disease.program.map( (p) => [p.programName, p])).values()];

                } else {
                    disease = {
                        disease: contract.diseaseName ? contract.diseaseName : 'Tout cancer',
                        programName: contract.programName,
                        program: [{
                            programName: contract.programName,
                            programCode: contract.programCode,
                            programContinuum: contract.programContinuum,
                            programDescription: contract.programDescription,
                            programId: contract.programId,
                            programPro: contract.programPro,
                        }],
                        medicationsByGroupName: {
                            '=Autres': [] // pour que les médicaments sans groupe soit en premier dans la liste.
                        },
                        groupName: {},
                    };
                    diseaseMap.set(contract.treatmentGroupId + '_' + contract.programName, disease);
                }
                let treatmentGroup = vm.contractualTreatmentGroupMap.get(contract.treatmentGroupId);
                if (treatmentGroup) {
                    if(treatmentGroup.medicationIds.length === 0) {
                        const allMedications = vm.medicationMap.get(-1);
                        // tous traitements éligibles
                        addDiseaseInfoToMedication(disease, allMedications);
                        addMedicationToDisease(disease, allMedications);
                    } else {
                        treatmentGroup.medicationIds.forEach( (mId) => {
                            const medication = vm.medicationMap.get(mId);
                            addDiseaseInfoToMedication(disease, medication);
                            addMedicationToDisease(disease, medication);
                        });
                    }
                } else {
                    // si pas de treatmentGroup alors tous les médicaments sont concernés
                    vm.medicationMap.forEach((m) => {
                        addDiseaseInfoToMedication(disease, m);
                    });

                }
            });

            vm.diseaseList = [...diseaseMap.values()];

            // génère les listes pour l'affichage des médicaments
            // on doit supprimer les médicaments qui sont dans "Tout cancer" des autres
            vm.diseaseListAkodom = diseaseListForProgram('AKO@dom');
            vm.diseaseListAkopharma = diseaseListForProgram('AKO@pharma');
            vm.diseaseListAkopro = diseaseListForProgram('AKO@PRO');
            vm.diseaseListPicto = diseaseListForProgram('PICTO');

            // // on regroupe les medicationsGroup et les maladies pour éviter d'afficher un même groupe plusieurs fois pour une même maladie
            vm.diseaseListAkodom = groupMedicationsGroupByDisease(vm.diseaseListAkodom);
            vm.diseaseListAkopharma = groupMedicationsGroupByDisease(vm.diseaseListAkopharma);
            vm.diseaseListAkopro = groupMedicationsGroupByDisease(vm.diseaseListAkopro);
            vm.diseaseListPicto = groupMedicationsGroupByDisease(vm.diseaseListPicto);

            sortDiseaseByName(vm.diseaseListAkodom);
            sortDiseaseByName(vm.diseaseListAkopharma);
            sortDiseaseByName(vm.diseaseListAkopro);
            sortDiseaseByName(vm.diseaseListPicto);

            vm.isLoading = false;
        }

        function diseaseListForProgram(programName) {
            return clearAllDiseaseMedication(_.cloneDeep(vm.diseaseList.filter( (d) => d.programName === programName && Object.keys(d.medicationsByGroupName).length)));

        }

        function groupMedicationsGroupByDisease(diseaseList) {
            const result = diseaseList.reduce((groupingMap, d) => {
                const m = groupingMap.get(d.disease);
                if (m && d.medicationsByGroupName) {
                    Object.keys(d.medicationsByGroupName).forEach( (key) => {
                        m.medicationsByGroupName = m.medicationsByGroupName ? m.medicationsByGroupName : {};
                        m.medicationsByGroupName[key] = [...new Set([...m.medicationsByGroupName[key] || [], ...d.medicationsByGroupName[key] || []]).values()];
                    });
                } else {
                    groupingMap.set(d.disease, d);
                }
                return groupingMap;
            }, new Map());

            return[...result.values()];
        }

        function addDiseaseInfoToMedication(disease, medication) {
            medication.programName = medication.programName ? medication.programName : [];
            medication.programName = [...new Set([...medication.programName, disease.programName]).values()];

            medication.program = medication.program ? medication.program : [];
            medication.program = [...new Map(
                [
                    ...medication.program.map( (p) => [p.programId, p]),
                    ...disease.program.map( (p) => [p.programId, p])
                ]).values()]
                .sort((p1, p2) => p1.programName.toLocaleUpperCase().localeCompare(p2.programName.toLocaleUpperCase()));

            medication.diseaseName = medication.diseaseName ? medication.diseaseName : {};

            medication.diseaseName[disease.programName] = [...new Set(medication.diseaseName[disease.programName]).add(disease.disease).values()];
        }

        function addMedicationToDisease(disease, medication) {
            const groupName =  medication.groupName || '=Autres';
            if ( disease.medicationsByGroupName[groupName]) {
                disease.medicationsByGroupName[groupName] = [...new Set(disease.medicationsByGroupName[groupName]).add(medication).values()]; // pour éviter les doublons
                disease.medicationsByGroupName[groupName].sort( (m1, m2) => m1.name.toLocaleUpperCase().localeCompare(m2.name.toLocaleUpperCase()));
            } else {
                disease.medicationsByGroupName[groupName] = [medication];
            }
        }

        function clearAllDiseaseMedication(list) {
            return list.map((d) => {
                Object.entries(d.medicationsByGroupName).forEach( ([medicationGroupName, medicationGroup]) => {

                    if (d.disease !== 'Tout cancer') {
                        d.medicationsByGroupName[medicationGroupName] = medicationGroup.filter( (medication) => {
                            return !medication.diseaseName[d.programName].includes('Tout cancer');
                        });
                    }

                    if (!d.medicationsByGroupName[medicationGroupName].length) {
                        delete d.medicationsByGroupName[medicationGroupName];
                    }
                    if (!Object.keys(d.medicationsByGroupName).length) {
                        d.medicationsByGroupName = null;
                    }
                });

                return d;
            });
        }

        function sortDiseaseByName(list) {
            list.sort( (d1, d2) => {
                if (d1.disease && d2.disease) {
                    return d1.disease.toLocaleUpperCase().localeCompare(d2.disease.toLocaleUpperCase());
                } else if (!d1.disease && !d2.disease) {
                    return 0;
                } else if (!d1.disease) {
                    return -1;
                } else {
                    return 1;
                }
            });
        }

        function onClickMedication(medication) {
            vm.medicationMap.get(medication.id);
            $uibModal.open({
                templateUrl: 'app/documents/medication-modal.html',
                controller: 'MedicationModalController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    medication : () => vm.medicationMap.get(medication.id) || medication
                }
            }).result.then(() => {
                // nothing
            }, () => {
                // nothing
            });
            vm.medicationSearchList = [];
        }

        function onPressEnter(e) {
            if (e.keyCode === 13) {
                vm.onClickSearch();
            }
        }

        function onClickSearch() {
            if (vm.medicationSearchList && vm.medicationSearchList.length) {
                vm.onClickMedication(vm.medicationSearchList[0]);
            }
        }

        function searchMedication() {
            if (vm.currentSearch && vm.currentSearch.length >= 3) {
                Medication.query({
                    'name.contains': vm.currentSearch,
                    size: 10,
                    sort: "name"
                }, (medicationList) => {
                    vm.medicationSearchList = medicationList;
                });
            } else {
                vm.medicationSearchList = [];
            }
        }

        function onBlurSearchInput($event) {
            if ($event.relatedTarget && $event.relatedTarget.id && $event.relatedTarget.id.startsWith('medication_')) {
                // nothing
            } else {
                vm.medicationSearchList = [];
            }
        }
    }
})();
