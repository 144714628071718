(function () {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('SidebarController', SidebarController);

    SidebarController.$inject = ['$rootScope', '$scope', '$state', 'Auth', 'Principal', '$uibModal', '$document', '$log', '$timeout', 'SideEffectReport', 'HealthFacility', 'Visit'];

    function SidebarController($rootScope, $scope, $state, Auth, Principal, $uibModal, $document, $log, $timeout, SideEffectReport, HealthFacility, Visit) {
        const vm = this;

        vm.$state = $state;

        (function ($, viewport) {
            $(document).ready(() => {
                initSidebar($, viewport);
            });
        })(jQuery, ResponsiveBootstrapToolkit);

        vm.unsubscribeAccountSelfUpdate = $rootScope.$on('continuumplatformApp:accountSelfUpdate', (event, account) => {
            vm.isPractitionerAway = isPractitionerAway(account.practitioner);
        });

        vm.unsubscribeTotalVisitTodoOrInProgress = $scope.$watch(
            () => Visit.totalVisitTodoOrInProgress,
            (newVal, oldVal) => {
                vm.totalVisitTodoOrInProgress = newVal;
            }
        );

        vm.$onInit = () => {
            getAccount();
        };

        vm.$onDestroy = () => {
            if (vm.unsubscribeAccountSelfUpdate) {
                vm.unsubscribeAccountSelfUpdate();
            }
            if (vm.unsubscribeTotalVisitTodoOrInProgress) {
                vm.unsubscribeTotalVisitTodoOrInProgress();
            }
        };

        function getAccount() {
            Principal.identity().then(account => {

                vm.account = account;
                vm.isPatient = account.authorities && account.authorities.includes('ROLE_PATIENT');
                vm.isAllowedToReadPatientHealthData = account.authorities && account.authorities.includes('PATIENT_HEALTH_READ');
                vm.isAdmin = account.authorities && account.authorities.includes('ROLE_ADMIN');
                vm.hasRoleFinance = account.authorities && account.authorities.includes('ROLE_FINANCE');
                vm.hasRoleBill = account.authorities && account.authorities.includes('BILL');
                vm.hasRoleEditor = account.authorities && account.authorities.includes('ROLE_EDITOR');
                vm.isCrpvUser = account.practitioner && account.practitioner.healthFacilityType === 'PV';

                if (account.practitioner) {
                    vm.isCoordination = account.practitioner.healthFacilityType === 'COORDINATION';
                    vm.isHospitalUser = account.practitioner.healthFacilityType === 'HOSPITAL';
                    vm.isCrpvUser = account.practitioner.healthFacilityType === 'PV';

                    vm.isDoctor = account.practitioner.job === 'J10';
                    vm.isPharmacist = account.practitioner.job === 'J21';
                    vm.isPharmacyTechnician = account.practitioner.job === 'J38';
                    vm.isNurse = account.practitioner.job === 'J60';
                    vm.isOtherPractitioner = account.practitioner.job === 'J99';

                    if (account.practitioner.healthFacilityId) {
                        HealthFacility.get({id: account.practitioner.healthFacilityId, view: 'summary'}, function (healthFacility) {
                            vm.healthFacility = healthFacility;

                            loadLocation().then(location => {
                                vm.location = location;
                            });

                            vm.fegala = vm.isAdmin
                                || (vm.healthFacility.trials && vm.healthFacility.trials.includes('FEGALA'));

                            vm.pecam = vm.healthFacility.features.includes('PECAM');

                            vm.telemonitoringScreen = vm.healthFacility.features.includes('ALERT_DASHBOARD');
                            vm.alertDashboardMDv1 = vm.healthFacility.features.includes('ALERT_DASHBOARD_V1');
                        });
                    }

                    vm.isPractitionerAway = isPractitionerAway(account.practitioner);
                }

                countPendingSideEffectReports();
                countStartableOrResumableVisit();
            });
        }

        function loadLocation() {
            if (!vm.healthFacility.features.includes('MULTIPLE_LOCATIONS')) {
                $log.debug('La fonctionnalité multisite n\'est pas activée.');
                return Promise.resolve();
            }
            if (!vm.account.authorities.includes('SELECT_LOCATION')) {
                $log.debug('La sélection de site n\'est pas autorisée.');
                const locationId = vm.account.getPractitionerFilter('LOCATION_ID');
                if (!locationId) {
                    $log.debug('Professionnel non affecté à un site.');
                    return Promise.resolve();
                }
                return HealthFacility.get({ id: locationId, view: 'summary' }).$promise;
            }
            $log.debug('La sélection de site est autorisée.');
            return Promise.resolve();
        }

        function initSidebar($, viewport) {
            $timeout(function () {
                $('[data-widget="tree"]').tree();
                $('.sidebar-menu a[ui-sref]').click(function () {
                    if (viewport.is('xs')) {
                        $('[data-toggle="push-menu"]').pushMenu('toggle');
                    }
                });
            });
        }

        function countPendingSideEffectReports() {
            if (vm.isCrpvUser) {
                SideEffectReport.countPending().then(function (response) {
                    $rootScope.pendingSideEffectReports = response.data;
                });
            } else {
                $rootScope.pendingSideEffectReports = null;
            }
        }

        function isPractitionerAway(practitioner) {
            const today = moment().format('yyyy-MM-DD');
            return (!!practitioner.leaveFrom && practitioner.leaveTo >= today) || (!!practitioner.leaveFrom && !practitioner.leaveTo);
        }

        function countStartableOrResumableVisit() {
            if (vm.isPatient) {
                Visit.countStartableOrResumableVisit();
            } else if (vm.account
                && vm.account.practitioner
                && vm.isAllowedToReadPatientHealthData
                && !vm.isCoordination
                && !vm.isHospitalUser
                && !vm.isCrpvUser) {
                Visit.countStartableOrResumableVisit(vm.account.practitioner.id);
            } else {
                Visit.setCountStartableOrResumableVisit(null);
            }
        }

    }
})();
