(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('PendingVisitsController', PendingVisitsController);

    PendingVisitsController.$inject = ['$filter', '$scope', '$state', '$log', 'filters', 'options', 'Principal', 'Visit', 'HealthFacility', 'Practitioner', 'HDJ', 'CloseDays'];

    function PendingVisitsController($filter, $scope, $state, $log, filters, options, Principal, Visit, HealthFacility, Practitioner, HDJ, CloseDays) {

        const vm = this;

        vm.savedFilters = {};
        vm.filters = filters;
        vm.options = options;
        vm.isLoading = true;

        vm.$onInit = () => {

            Principal.identity().then(account => {

                if (!account.practitioner || !account.practitioner.healthFacilityId) {
                    $log.error('Only practitioners in facilities can use PendingVisitsController');
                    $state.go('accessdenied');
                    return;
                }

                vm.account = account;

                loadHealthFacility()
                    .then(loadDiseaseDivisions)
                    .then(loadLocations)
                    .then(loadTherapyType)
                    .then(loadNextWorkedDay)
                    .then(loadVisits)
                    .then(() => {
                        $scope.$watch('vm.options.mode', (newVal, oldVal) => {
                            if(newVal === oldVal) {
                                return;
                            }
                            loadVisits();
                        })
                    });

            });

        };

        vm.$onDestroy = () => {
            if (vm.unwatchTherapyType) {
                vm.unwatchTherapyType();
            }
            if (vm.unwatchDiseaseDivisionName) {
                vm.unwatchDiseaseDivisionName();
            }
            if (vm.unwatchAssigneeId) {
                vm.unwatchAssigneeId();
            }
        };

        vm.applyFromDate = loadVisits;

        vm.previous = () => {
            vm.filters.fromDate = moment(vm.filters.fromDate).subtract(vm.options.mode === 'DAY' ? 1 : 7, 'days').toDate();
            loadVisits();
        }

        vm.next = () => {
            vm.filters.fromDate = moment(vm.filters.fromDate).add(vm.options.mode === 'DAY' ? 1 : 7, 'days').toDate();
            loadVisits();
        }

        function loadHealthFacility() {
            return HealthFacility.get({
                view: 'summary',
                id: vm.account.practitioner.healthFacilityId
            }).$promise.then(facility => vm.facility = facility);
        }

        function loadDiseaseDivisions() {
            vm.facility.diseaseDivisions = vm.facility.diseaseDivisions || [];
            vm.enableDiseaseDivisionFilter = vm.facility.diseaseDivisions.length > 0;
            if(vm.enableDiseaseDivisionFilter) {

                // group by division name reduce to 1 division by name
                vm.divisions = vm.facility.diseaseDivisions.reduce((divisions, division) => {
                    divisions[division.name] = division.diseaseIds;
                    return divisions;
                }, {});

                // restore previously selected division with updated data
                vm.diseaseDivisionName = vm.account.getPractitionerFilter('DISEASE_DIVISION_NAME');
                if(vm.diseaseDivisionName) {
                    if(vm.divisions[vm.diseaseDivisionName]) {
                        vm.filters.diseaseId = undefined;
                        vm.savedFilters.diseaseIds = vm.divisions[vm.diseaseDivisionName];
                    } else {
                        vm.account.removePractitionerFilter('DISEASE_DIVISION_NAME');
                        vm.diseaseDivisionName = undefined;
                        vm.savedFilters.diseaseIds = undefined;
                    }
                }
                vm.unwatchDiseaseDivisionName = $scope.$watch('vm.diseaseDivisionName', onChangeDivision);
            }
        }

        function onChangeDivision(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            } else if (newVal) {
                vm.filters.diseaseId = undefined;
                vm.savedFilters.diseaseIds = vm.divisions[newVal];
            } else {
                vm.savedFilters.diseaseIds = undefined;
            }
            Principal.setPractitionerFilter('DISEASE_DIVISION_NAME', newVal);
            loadVisits();
        }

        function loadLocations() {
            if (!vm.facility.features.includes('MULTIPLE_LOCATIONS')) {
                $log.debug('La fonctionnalité multisite n\'est pas activée.');
                return Promise.resolve();
            }
            if (!vm.account.authorities.includes('SELECT_LOCATION')) {
                $log.debug('La sélection de site n\'est pas autorisée.');
                return Promise.resolve();
            }
            $log.debug('La fonctionnalité multisite est activée et la sélection autorisée. Chargement des sites...');
            return HealthFacility.query({
                view: 'summary',
                'healthFacilityId.equals': vm.facility.id,
                sort: 'name',
                size: 99, // TODO hard-coded size
            }).$promise.then(locations => {
                const countOfLocations = locations.length;
                $log.debug(`${countOfLocations} site(s) trouvé(s).`);
                vm.enableLocationFilter = countOfLocations > 0;
                if (vm.enableLocationFilter) {
                    vm.locations = locations;
                    vm.savedFilters.locationId = vm.account.getPractitionerFilter('LOCATION_ID');
                    vm.unwatchAssigneeId = $scope.$watch('vm.savedFilters.locationId', onChangeAssigneeId);
                }
            });
        }

        function onChangeAssigneeId(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            Principal.setPractitionerFilter('LOCATION_ID', newVal);
            loadVisits();
        }

        function loadTherapyType() {
            const savedTherapyType = vm.account.getPractitionerFilter('THERAPY_TYPE');
            if(['hdj', 'hah'].includes(savedTherapyType)) {
                vm.savedFilters.therapyType = savedTherapyType;
            }
            vm.unwatchTherapyType = $scope.$watch('vm.savedFilters.therapyType', onChangeTherapyType);
        }

        function onChangeTherapyType(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            Principal.setPractitionerFilter('THERAPY_TYPE', newVal);
            loadVisits();
        }

        function loadNextWorkedDay() {
            if(vm.filters.fromDate !== null) {
                return Promise.resolve();
            }
            return CloseDays.get(vm.account.practitioner.healthFacilityId, moment().format('yyyy-MM-DD'))
                .then(result => {
                    vm.filters.fromDate = moment(result.data.next).toDate();
                });
        }

        function loadVisits() {
            vm.isLoading = true;
            vm.visits = null;
            return HDJ.query({
                mode: vm.options.mode,
                diseaseIds: getDiseaseIdIn(),
                fromDate: moment(vm.filters.fromDate).format('yyyy-MM-DD'),
                types: types(),
                assigneeId: vm.savedFilters.locationId ? vm.savedFilters.locationId : undefined,
            }).then((response) => {
                onSuccess(response.data, response.headers);
            }).finally(() => {
                vm.isLoading = false;
            });
        }

        function types() {
            if (vm.savedFilters.therapyType === 'hdj') {
                return ['HOSPITAL'];
            }
            if (vm.savedFilters.therapyType === 'hah') {
                return ['HAH'];
            }
            return undefined;
        }

        function onSuccess(data, headers) {
            vm.visits = data;
            vm.loadedDate = vm.filters.fromDate;
            if(vm.options.mode === 'DAY') {
                vm.loadedDateTo = null;
            } else if(vm.options.mode === 'WEEK') {
                vm.loadedDateTo = moment(vm.loadedDate).add(6, 'days').toDate();
            }
            vm.dates = $filter('groupBy')(vm.visits
                .map(visit => {
                    visit.plannedDateString = moment(visit.plannedDate).format('yyyy-MM-DD');
                    return visit;
                }), 'plannedDateString');
        }

        function getDiseaseIdIn() {
            if (vm.savedFilters.diseaseIds && vm.savedFilters.diseaseIds.length) {
                return vm.savedFilters.diseaseIds;
            }
            return undefined;
        }

    }
})();
