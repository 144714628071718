(() => {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('VisitConfirmDialogController', VisitConfirmDialogController);

    VisitConfirmDialogController.$inject = ['$timeout', '$scope', '$uibModalInstance', 'visit', 'Visit', 'HealthFacility'];

    function VisitConfirmDialogController($timeout, $scope, $uibModalInstance, visit, Visit, HealthFacility) {
        const vm = this;

        vm.visit = visit;

        $timeout(function () {
            angular.element('button[type=submit]').focus();
        });

        loadPatientHealthFacility().then(() => {
            vm.isPatientNotificationFeatureEnabled = vm.facility.features.includes('VISIT_CONFIRMED_PATIENT_NOTIFICATION');
        })

        vm.clear = () => {
            $uibModalInstance.dismiss('cancel');
        };

        vm.save = () => {
            vm.isSaving = true;
            Visit.confirm({
                id: vm.visit.id
            }, {
                plannedDate: vm.visit.plannedDate,
            }, onSaveSuccess, onSaveError);
        };

        function loadPatientHealthFacility() {
            return HealthFacility.get({
                view: 'summary',
                id: vm.visit.patient.healthFacilityId
            }).$promise.then(facility => vm.facility = facility);
        }

        function onSaveSuccess() {
            $scope.$emit('continuumplatformApp:visitUpdate', vm.visit.id);
            $uibModalInstance.close(vm.visit);
            vm.isSaving = false;
        }

        function onSaveError() {
            vm.isSaving = false;
        }
    }
})();
