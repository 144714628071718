(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('Protocol', Protocol);

    Protocol.$inject = ['$resource'];

    function Protocol($resource) {
        const resourceUrl = 'api/protocols';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
        });
    }
})();
