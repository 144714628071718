(function() {
    'use strict';

    angular
        .module('continuumplatformApp')
        .controller('DoneVisitsController', DoneVisitsController);

    DoneVisitsController.$inject = ['$log', '$scope', '$state', 'moment', 'filters', 'paginationConstants', 'pagingParams', 'ParseLinks', 'Principal', 'HealthFacility', 'VisitAlerts', 'Visit'];

    function DoneVisitsController($log, $scope, $state, moment, filters, paginationConstants, pagingParams, ParseLinks, Principal, HealthFacility, VisitAlerts, Visit) {

        const vm = this;

        vm.loading = true;
        vm.savedFilters = {};
        vm.filters = filters;
        vm.page = pagingParams.page;
        vm.predicate = pagingParams.predicate;
        vm.ascending = pagingParams.ascending;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.totalItems = null;
        vm.visits = null;

        if(vm.filters.pre !== null && vm.filters.pre !== true && vm.filters.pre !== false) {
            vm.filters.pre = null;
        }

        vm.loadVisits = loadVisits;
        vm.refreshContent = refreshContent;

        vm.$onInit = () => {

            Principal.identity().then(account => {

                if (!account || !account.practitioner || !account.practitioner.healthFacilityId) {
                    $log.error('Only practitioners in facilities can use DoneVisitsController');
                    $state.go('accessdenied');
                    return;
                }

                vm.account = account;
                vm.practitioner = account.practitioner;

                // facility doctor
                if (account.practitioner.job === 'J10'
                    && account.practitioner.healthFacilityType === 'HOSPITAL') {
                    if (angular.isUndefined(vm.filters.facilityDoctorId)) {
                        vm.filters.facilityDoctorId = account.practitioner.id;
                    }
                    vm.forceFacilityDoctor = account.practitioner;
                }
                if (angular.isUndefined(vm.filters.facilityDoctorId)) {
                    vm.filters.facilityDoctorId = null;
                }

                // load
                loadHealthFacility()
                    .then(loadDiseaseDivisions)
                    .then(loadTrials)
                    .then(loadLocations)
                    .then(loadAll);

            });

            vm.unwatchTherapyType = $scope.$watch('vm.savedFilters.therapyType', (newVal, oldVal) => {
                if (oldVal !== newVal) {
                    refreshContentAndFacilityDoctors();
                }
            });

        };

        vm.$onDestroy = () => {
            if (vm.unwatchTherapyType) {
                vm.unwatchTherapyType();
            }
            if (vm.unwatchDiseaseDivisionName) {
                vm.unwatchDiseaseDivisionName();
            }
            if (vm.unwatchLocationId) {
                vm.unwatchLocationId();
            }
        };

        vm.transition = () => {
            $state.go($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.ascending ? 'asc' : 'desc'),
                filters: vm.filters,
            }).then(loadAll);
        };

        vm.toggleScoreFilter = (score) => {
            vm.filters.biology = undefined;
            vm.filters.scoreEqualTo = vm.filters.scoreEqualTo === score ? undefined : score;
            refreshContent();
        };

        vm.toggleBiologyFilter = () => {
            vm.filters.biology = vm.filters.biology ? undefined : true;
            vm.filters.other = undefined;
            vm.filters.scoreEqualTo = undefined;
            refreshContent();
        };

        vm.toggleOtherFilter = () => {
            vm.filters.biology = undefined;
            vm.filters.other = vm.filters.other ? undefined : true;
            vm.filters.scoreEqualTo = undefined;
            refreshContent();
        };

        function refreshContentAndFacilityDoctors() {
            resetPage().then(loadAll);
        }

        function refreshContent() {
            resetPage().then(loadVisits);
        }

        function resetPage() {
            vm.page = 1;
            return $state.go($state.$current, {
                page: vm.page,
            });
        }

        function loadHealthFacility() {
            return HealthFacility.get({
                view: 'summary',
                id: vm.practitioner.healthFacilityId
            }).$promise.then(facility => vm.facility = facility);
        }

        function loadDiseaseDivisions() {
            vm.facility.diseaseDivisions = vm.facility.diseaseDivisions || [];
            vm.enableDiseaseDivisionFilter = vm.facility.diseaseDivisions.length > 0;
            if (vm.enableDiseaseDivisionFilter) {

                // group by division name reduce to 1 division by name
                vm.divisions = vm.facility.diseaseDivisions.reduce((divisions, division) => {
                    divisions[division.name] = division.diseaseIds;
                    return divisions;
                }, {});

                // restore previously selected division with updated data
                vm.diseaseDivisionName = vm.account.getPractitionerFilter('DISEASE_DIVISION_NAME');
                if (vm.diseaseDivisionName) {
                    if (vm.divisions[vm.diseaseDivisionName]) {
                        vm.savedFilters.diseaseIds = vm.divisions[vm.diseaseDivisionName];
                    } else {
                        vm.diseaseDivisionName = undefined;
                        vm.savedFilters.diseaseIds = undefined;
                    }
                }
                vm.unwatchDiseaseDivisionName = $scope.$watch('vm.diseaseDivisionName', onChangeDivision);
            }
        }

        function loadTrials() {
            vm.enableTrialFilter = vm.facility.trials.length > 0;
            if (vm.enableTrialFilter) {
                vm.trials = vm.facility.trials;
                vm.savedFilters.trial = vm.account.getPractitionerFilter('TRIAL');
                vm.unwatchTrial = $scope.$watch('vm.savedFilters.trial', onChangeTrial);
            }
        }

        function loadLocations() {
            if (!vm.facility.features.includes('MULTIPLE_LOCATIONS')) {
                $log.debug('La fonctionnalité multisite n\'est pas activée.');
                return Promise.resolve();
            }
            if (!vm.account.authorities.includes('SELECT_LOCATION')) {
                $log.debug('La sélection de site n\'est pas autorisée.');
                return Promise.resolve();
            }
            $log.debug('La fonctionnalité multisite est activée et la sélection autorisée. Chargement des sites...');
            return HealthFacility.query({
                view: 'summary',
                'healthFacilityId.equals': vm.facility.id,
                size: 99, // TODO hard-coded size
            }).$promise.then(locations => {
                const countOfLocations = locations.length;
                $log.debug(`${countOfLocations} site(s) trouvé(s).`);
                vm.enableLocationFilter = countOfLocations > 0;
                if (vm.enableLocationFilter) {
                    vm.locations = locations;
                    vm.savedFilters.locationId = vm.account.getPractitionerFilter('LOCATION_ID');
                    vm.unwatchLocationId = $scope.$watch('vm.savedFilters.locationId', onChangeLocationId);
                }
            });
        }

        function onChangeDivision(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            } else if (newVal) {
                vm.savedFilters.diseaseIds = vm.divisions[newVal];
            } else {
                vm.savedFilters.diseaseIds = undefined;
            }
            Principal.setPractitionerFilter('DISEASE_DIVISION_NAME', newVal);
            refreshContentAndFacilityDoctors();
        }

        function onChangeTrial(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            Principal.setPractitionerFilter('TRIAL', newVal);
            refreshContentAndFacilityDoctors();
        }

        function onChangeLocationId(newVal, oldVal) {
            if (newVal === oldVal) {
                return;
            }
            Principal.setPractitionerFilter('LOCATION_ID', newVal);
            refreshContentAndFacilityDoctors();
        }

        function loadAll() {
            loadDistinctFacilityDoctors();
            loadVisits();
        }

        function loadVisits() {
            const requestedPage = vm.page;
            vm.loading = true;
            vm.visits = null;
            VisitAlerts.query({
                telemonitoring: true,
                acknowledged: vm.filters.showHistory ? undefined : false,
                patientAlive: true,
                scoreEqualTo: vm.filters.scoreEqualTo,
                pre: vm.filters.pre,
                biology: vm.filters.biology,
                other: vm.filters.other,
                locationId: vm.savedFilters.locationId || undefined,
                'status.equals': 'DONE',
                'diseaseId.in': getDiseaseIdIn(),
                'hdj.equals': vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hdj' : undefined,
                'hah.equals': vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hah' : undefined,
                'trial.equals': vm.savedFilters.trial || undefined,
                'facilityDoctorId.equals': vm.filters.facilityDoctorId || undefined,
                page: requestedPage - 1,
                size: vm.itemsPerPage,
                sort: sort(),
            })
                .then(response =>
                    onSuccess(requestedPage, response.data, response.headers))
                .catch(error => {
                    $log.error('Error while loading data', error);
                    vm.totalItems = null;
                    vm.visits = null;
                })
                .finally(() => {
                    vm.loading = false;
                });
        }

        function sort() {
            const result = [vm.predicate + ',' + (vm.ascending ? 'asc' : 'desc')];
            if (vm.predicate !== 'id') {
                result.push('id');
            }
            return result;
        }

        function loadDistinctFacilityDoctors() {
            Visit.queryDistinctFacilityDoctors({
                telemonitoring: true,
                'status.equals': 'DONE',
                'diseaseId.in': getDiseaseIdIn(),
                'hdj.equals': vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hdj' : undefined,
                'hah.equals': vm.savedFilters.therapyType ? vm.savedFilters.therapyType === 'hah' : undefined,
                'trial.equals': vm.savedFilters.trial || undefined,
                locationId: vm.savedFilters.locationId || undefined,
            }, (result) => {
                vm.facilityDoctors = result;
                if (!result.length && vm.forceFacilityDoctor) {
                    vm.facilityDoctors.push(vm.forceFacilityDoctor);
                }
            });
        }

        function onSuccess(requestedPage, data, headers) {
            vm.visits = data;
            vm.links = ParseLinks.parse(headers('link'));
            vm.totalItems = parseInt(headers('X-Total-Count'));
            vm.page = requestedPage;
        }

        function getDiseaseIdIn() {
            if (vm.savedFilters.diseaseIds && vm.savedFilters.diseaseIds.length) {
                return vm.savedFilters.diseaseIds;
            }
            return undefined;
        }

    }
})();
